import { API } from 'aws-amplify'
import { schema, normalize } from 'normalizr'

import { PROFILE_API_NAME } from '../../utils/env'

const profilesSchema = new schema.Entity('profiles')

const OrganizationService = {
  async getUserInfo() {
    return API.get(PROFILE_API_NAME, '/userinfo')
  },

  async listProfiles(q) {
    const response = await API.get(PROFILE_API_NAME, '/organization-profiles', {
      queryStringParameters: { q },
    })
    const normalized = normalize(response.items, [profilesSchema])
    const { profiles } = normalized.entities

    return profiles
  },

  async submitSupportTicket(message) {
    return API.post(PROFILE_API_NAME, '/support-tickets', {
      body: {
        description: message,
        source: 'support_ticket_web',
      },
    })
  },
}

export { OrganizationService }
