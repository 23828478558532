import { all, put } from 'redux-saga/effects'

import { auditSagas } from '../modules/audit'
import { authSagas, initializeCurrentUser } from '../modules/auth'
import { crewSagas } from '../modules/crews'
import { organizationSagas } from '../modules/organization'
import { jobSagas } from '../modules/job'
import { schedulerSagas } from '../modules/scheduler'
import { productSagas } from '../modules/product'
import { forecastSagas } from '../modules/forecast'
import { supportSagas } from '../modules/support'

function* rootSaga() {
  yield all([
    ...auditSagas,
    ...authSagas,
    ...crewSagas,
    ...organizationSagas,
    ...productSagas,
    ...jobSagas,
    ...schedulerSagas,
    ...forecastSagas,
    ...supportSagas,
    bootstrapSaga(),
  ])
}

function* bootstrapSaga() {
  yield put(initializeCurrentUser.request())
}

export { rootSaga }
