import { mean, sum } from '@soltalabs/ramda-extra'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'

import { ReactComponent as ClockIcon } from '../../assets/clock.svg'
import { ReactComponent as ThermoIcon } from '../../assets/thermo-25.svg'
import { ReactComponent as UmbrellaIcon } from '../../assets/umbrella.svg'
import { ReactComponent as WindIcon } from '../../assets/wind.svg'
import {
  assessTemperature,
  assessHumidity,
  assessRainfall,
} from '../../utils/assessment'

const Root = styled.div({
  minWidth: '120px',
})

const FlexRow = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
})

const IconWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',

  width: '24px',
  height: '24px',
})

function AssessmentTooltipContents({ assessment, product }) {
  const {
    score,
    assessmentValidityPeriodStart,
    assessmentValidityPeriodEnd,
  } = assessment

  const startTime = moment(assessmentValidityPeriodStart).format('ddd H:mm')
  const endTime = moment(assessmentValidityPeriodEnd).format('H:mm')

  const {
    temperatureFeelLike = [],
    relativeHumidity = [],
    quantitativeRainfall = [],
  } = assessment.algorithmInputs

  const meanTemp = mean(temperatureFeelLike)
  const meanHumidity = mean(relativeHumidity)
  const sumRainfall = sum(quantitativeRainfall)

  console.log(quantitativeRainfall)
  console.log(sumRainfall)

  const algorithm = product.applicableAlgorithm.split(':')[0].toUpperCase()

  const tempScore = assessTemperature(algorithm, meanTemp)
  const humidityScore = assessHumidity(algorithm, meanHumidity)
  const rainfallScore = assessRainfall(algorithm, sumRainfall)

  const assessmentSummaries = [
    {
      Icon: ThermoIcon,
      score: tempScore,
      label: 'Cold',
    },
    {
      Icon: UmbrellaIcon,
      score: rainfallScore,
      label: 'Rain',
    },
    {
      Icon: WindIcon,
      score: humidityScore,
      label: 'Humid',
    },
  ]

  return (
    <Root>
      <FlexRow style={{ marginBottom: '4px' }}>
        <IconWrapper>
          <ClockIcon width={16} height={16} fill="white" />
        </IconWrapper>

        <span style={{ marginLeft: '4px' }}>
          {startTime} - {endTime}
        </span>
      </FlexRow>

      {score === 0
        ? null
        : assessmentSummaries.map(({ Icon, score: individualScore, label }) => {
            if (individualScore === 0) {
              return null
            }

            return (
              <FlexRow>
                <IconWrapper>
                  <Icon width={24} height={24} fill="#FF7878" />
                </IconWrapper>

                <span style={{ marginLeft: '4px', color: '#FF7878' }}>{label}</span>
              </FlexRow>
            )
          })}
    </Root>
  )
}

export { AssessmentTooltipContents }
