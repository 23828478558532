import React from 'react'

import { Container, Title, Link, Text } from '../common/styles'

function AccountActivationErrorPage() {
  return (
    <Container>
      <Title>Account Activation Error</Title>
      <Text>
        The activation link is invalid or has expired. Please contact Zeus support for
        help.
      </Text>

      <Link to="/">RETURN TO HOMEPAGE</Link>
    </Container>
  )
}

export { AccountActivationErrorPage }
