import { isEmpty } from '@soltalabs/ramda-extra'
import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import { deepMemo } from '../../../utils/react'
import { EmptyListPrompt, StyledCircularProgress } from '../../common/styles'
import { UnassignedJobCard } from '../UnassignedJobCard'

import { Container, ListContainer } from './styles'

function View({ jobList, isFetchJobsLoading, isFetchNextJobsLoading, onScroll }) {
  return (
    <Container onScroll={onScroll}>
      {isFetchJobsLoading && <StyledCircularProgress />}
      {isEmpty(jobList) && !isFetchJobsLoading && (
        <EmptyListPrompt>No jobs available</EmptyListPrompt>
      )}
      <Droppable droppableId="unassigned-job-list">
        {(droppableProvided, snapshot) => (
          <ListContainer
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {jobList.map((job, index) => (
              <Draggable key={job.id} draggableId={job.id} index={index}>
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    <UnassignedJobCard key={job.id} cols={1} spacing={10} job={job} />
                  </div>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </ListContainer>
        )}
      </Droppable>
      {isFetchNextJobsLoading && <StyledCircularProgress color="secondary" />}
    </Container>
  )
}

const UnassignedJobListView = deepMemo(View)

export { UnassignedJobListView }
