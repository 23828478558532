import { API } from 'aws-amplify'

import { PROFILE_API_NAME } from '../../utils/env'

const AuditService = {
  async recordEvent(event) {
    await API.post(PROFILE_API_NAME, '/audits', {
      body: event,
    })
  },
}

export { AuditService }
