import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { CREWS } from '../../../constants/crews'
import { addCrewMember, createCrewInvitation } from '../../../modules/crews'
import {
  getOrganizationProfilesNotInCrew,
  fetchOrganizationProfiles,
} from '../../../modules/organization'

import { AddMemberRowView } from './view'

function AddMemberRow({ crewId }) {
  const dispatch = useDispatch()
  const [memberInputValue, setMemberInputValue] = useState('')
  const profiles = useSelector((state) =>
    getOrganizationProfilesNotInCrew(state, crewId)
  )
  const memberSuggestions = profiles.map((profile) => ({
    type: CREWS.SUGGESTION_TYPES.MEMBER,
    item: profile,
  }))

  function onMemberInputChange(e, { newValue }) {
    if (e.key === 'ArrowDown' || e.key === 'ArrowUp') {
      return
    }
    setMemberInputValue(newValue)
  }

  function onMemberInputBlur() {
    setMemberInputValue('')
  }

  function getSuggestionValue() {
    return ''
  }

  function onMemberSuggestionSelected(e, { suggestion }) {
    if (suggestion.type === CREWS.SUGGESTION_TYPES.MEMBER) {
      dispatch(addCrewMember.request(crewId, suggestion.item.id))
    }
    if (suggestion.type === CREWS.SUGGESTION_TYPES.INVITE) {
      dispatch(createCrewInvitation.request(crewId, suggestion.item.email))
    }
  }

  function onSuggestionsFetchRequested({ value }) {
    dispatch(fetchOrganizationProfiles.request(value))
  }

  return (
    <AddMemberRowView
      memberSuggestions={memberSuggestions}
      memberInputValue={memberInputValue}
      getSuggestionValue={getSuggestionValue}
      onMemberSuggestionSelected={onMemberSuggestionSelected}
      onMemberInputChange={onMemberInputChange}
      onMemberInputBlur={onMemberInputBlur}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
    />
  )
}

export { AddMemberRow }
