import {
  prop,
  path,
  pipe,
  indexOf,
  curry,
  set,
  lensIndex,
  defaultTo,
  mergeDeepRight,
  isNotNilOrEmpty,
  isNilOrEmpty,
} from '@soltalabs/ramda-extra'
import moment from 'moment'

import { COLORS } from '../../constants/colors'

const swapByValue = curry((value1, value2, list) => {
  const index1 = indexOf(value1, list)
  const index2 = indexOf(value2, list)

  return pipe(set(lensIndex(index1), value2), set(lensIndex(index2), value1))(list)
})

const swapByIndex = curry((index1, index2, list) => {
  const value1 = list[index1]
  const value2 = list[index2]

  return pipe(set(lensIndex(index1), value2), set(lensIndex(index2), value1))(list)
})

const reduceIndexMap = (acc, job, index) => {
  const { priorityNumber } = job
  acc[priorityNumber] = index
  return acc
}

const getPinColor = (value) => {
  if (value <= 0) {
    return COLORS.PIN.GREEN
  }
  if (value < 1) {
    return COLORS.PIN.YELLOW
  }
  if (value > 1) {
    return COLORS.PIN.RED
  }
  return COLORS.PIN.RED
}

const getAssessmentScore = (assessments, selectedTime) => {
  const assessment = assessments.find((block) => {
    const start = moment(block.assessmentValidityPeriodStart)
    const end = moment(block.assessmentValidityPeriodEnd)
    const time = moment(selectedTime)

    return time.isSame(start) ?? time.isBetween(start, end)
  })

  return prop('score', assessment) ?? path([0, 'score'], assessments)
}

function mergeEntities(prev, next) {
  // When creating a new job and immediately fetching the job list afterwards, the
  // last created job will appear to be missing its risk assessments. This is due to
  // a synchronization issue between MongoDB's read and write clusters. To solve this
  // problem, we need to retain the risk assessments that are provided when we create
  // a job, so that they aren't overwritten by an empty array when we re-fetch the job
  // list.
  //
  // This utility function was written with the purpose of addressing the above
  // described issue.

  const merged = prev
  const getAssessments = prop('assessments')

  for (const [key, nextEntity] of Object.entries(next)) {
    const prevEntity = defaultTo({})(prev[key])
    const mergedEntity = mergeDeepRight(prevEntity, nextEntity)

    // `mergeDeepRight` will overwrite a non-empty array with an empty array, so we need
    // to check if a job's assessments was non-empty before being replaced with an
    // empty array, and patch it back in.
    if (
      isNotNilOrEmpty(getAssessments(prevEntity)) &&
      isNilOrEmpty(getAssessments(nextEntity))
    ) {
      mergedEntity.assessments = prevEntity.assessments
    }

    merged[key] = mergedEntity
  }

  return merged
}

export {
  swapByValue,
  swapByIndex,
  reduceIndexMap,
  getAssessmentScore,
  getPinColor,
  mergeEntities,
}
