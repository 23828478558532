import React from 'react'

import { JOBS } from '../../../constants/jobs'

import {
  StyledSelect,
  StyledInputLabel,
  StyledFormControl,
  StyledMenuItem,
} from './styles'

const options = [JOBS.SORTBY.PRIORITY, JOBS.SORTBY.FINISH_BY, JOBS.SORTBY.ASSESSMENTS]

const SortbySelect = ({ value = JOBS.SORTBY.PRIORITY.value, onChange }) => (
  <StyledFormControl className="dropdown-sort-by" variant="outlined">
    <StyledInputLabel>Sort by</StyledInputLabel>
    <StyledSelect labelWidth={55} value={value} onChange={onChange}>
      {options.map((option) => (
        <StyledMenuItem key={option.value} value={option.value}>
          {option.name}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  </StyledFormControl>
)

export { SortbySelect }
