import { IconButton, Button } from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { path, prop, isNil } from '@soltalabs/ramda-extra'
import moment from 'moment'
import React, { useState, useEffect } from 'react'

import { JOBS } from '../../../constants/jobs'
import { Assessment } from '../Assessment'

import {
  NameLabelContainer,
  JobContainer,
  JobContent,
  NameLabel,
  PriorityNumber,
  SectionContainer,
  InfoLabel,
  ProductDetailsContainer,
  FinishByContainer,
  FinishBy,
  DayLabel,
  AssessmentContainer,
  ProductColorBar,
  DeleteButtonsContainer,
  StyledLinearProgress,
  InfoContainer,
  ScheduleInfo,
  CrewNameIndicator,
} from './styles'

const JobCardView = ({
  job,
  priorityNumber,
  sortDisabled,
  cols,
  spacing,
  product,
  isDeleting,
  isSelected,
  onAssessmentHovered,
  onSelectJob,
  onMouseEnterJob,
  onMouseLeaveJob,
  onDeletingJob,
  onUndoDeleteJob,
  onDeleteJob,
  isCard = true,
}) => {
  const [isHoveringAssessment, setIsHoveringAssessment] = useState(false)

  const isHighRisk = job.finishBy === JOBS.MAX_FINISH_BY_DATE || isNil(job.finishBy)
  const crewName = path(['crew', 'name'], job)
  const scheduledAt = prop('scheduledAt', job)

  useEffect(() => {
    let timeout

    if (isHoveringAssessment) {
      timeout = setTimeout(() => {
        onAssessmentHovered()
      }, 350)
    }

    return () => clearTimeout(timeout)
  }, [isHoveringAssessment, onAssessmentHovered])

  function handleMouseEnterAssessment() {
    setIsHoveringAssessment(true)
  }

  function handleMouseLeaveAssessment() {
    setIsHoveringAssessment(false)
  }

  return (
    <JobContainer
      sortDisabled={sortDisabled}
      cols={cols}
      spacing={spacing}
      card={isCard}
    >
      <JobContent
        selected={isSelected}
        card={isCard ? 1 : 0}
        onMouseEnter={onMouseEnterJob}
        onMouseLeave={onMouseLeaveJob}
        onClick={onSelectJob}
      >
        {crewName && (
          <ScheduleInfo>
            <CrewNameIndicator>{crewName}</CrewNameIndicator>
          </ScheduleInfo>
        )}
        <NameLabelContainer>
          <NameLabel>
            {!isNil(priorityNumber) && (
              <PriorityNumber>{priorityNumber}</PriorityNumber>
            )}
            {job.jobName}
          </NameLabel>
          {isCard &&
            (isDeleting ? (
              <DeleteButtonsContainer>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  onClick={onDeleteJob}
                >
                  Confirm
                </Button>

                <Button color="primary" size="small" onClick={onUndoDeleteJob}>
                  Undo
                </Button>
              </DeleteButtonsContainer>
            ) : (
              <DeleteButtonsContainer>
                <IconButton onClick={onDeletingJob}>
                  <Delete fontSize="small" />
                </IconButton>
              </DeleteButtonsContainer>
            ))}
        </NameLabelContainer>

        <InfoContainer>
          {scheduledAt && (
            <InfoLabel>
              Scheduled at: <b>{moment(scheduledAt).format('dddd Do MMM')}</b>
            </InfoLabel>
          )}

          <InfoLabel>
            Location: <b>{job.address || 'ADDRESS'}</b>
          </InfoLabel>
        </InfoContainer>

        <SectionContainer>
          <ProductColorBar color={prop('color', product) || 'blue'} />

          <ProductDetailsContainer>
            <InfoLabel>
              Product:{' '}
              <b style={{ color: prop('color', product) || 'blue' }}>
                {prop('name', product)}
              </b>
            </InfoLabel>

            <InfoLabel>
              Application Rate: <b>{prop('applicationRate', product)}</b>
            </InfoLabel>
          </ProductDetailsContainer>

          <FinishByContainer>
            <InfoLabel>Finish by: </InfoLabel>
            <FinishBy highRisk={isHighRisk}>
              {!isHighRisk ? moment(job.finishBy).format('DD MMM H:mm') : 'High Risk'}
            </FinishBy>
          </FinishByContainer>
        </SectionContainer>

        <SectionContainer>
          <DayLabel align="left">NOW</DayLabel>
          <DayLabel>TOMORROW</DayLabel>
          <DayLabel align="right">DAY AFTER</DayLabel>
        </SectionContainer>

        {job.assessments ? (
          <AssessmentContainer>
            {job.assessments.map((assessment) => (
              <Assessment
                key={assessment.assessmentValidityPeriodStart}
                product={product}
                assessment={assessment}
                onMouseEnter={handleMouseEnterAssessment}
                onMouseLeave={handleMouseLeaveAssessment}
              />
            ))}
          </AssessmentContainer>
        ) : (
          <StyledLinearProgress />
        )}
      </JobContent>
    </JobContainer>
  )
}

export { JobCardView }
