import {
  createSelector,
  composeSelectors,
  createIsLoadingSelector,
  createErrorMessageSelector,
} from '@soltalabs/react.redux-toolkit'
import { submitSupportTicket } from './actions'

const getModuleState = createSelector('support')
const getShowModal = composeSelectors(getModuleState, createSelector('showModal'))
const getIsSubmittingSupportTicket = createIsLoadingSelector([submitSupportTicket])
const getSubmitSupportTicketErrorMessage = createErrorMessageSelector(
  submitSupportTicket,
  { unique: false }
)

export {
  getShowModal,
  getIsSubmittingSupportTicket,
  getSubmitSupportTicketErrorMessage,
}
