import styled from 'styled-components'

const Container = styled.ul`
  height: 62vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  margin: -5px;
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  scrollbar-width: none;
`

const ListContainer = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  background: ${(props) => (props.isDraggingOver ? '#e4e4e4' : 'white')};
`

export { Container, ListContainer }
