import { FormControl, Select, InputLabel, MenuItem } from '@material-ui/core'
import styled from 'styled-components'

const StyledFormControl = styled(FormControl)`
  && {
    width: 100%;
  }
`

const StyledInputLabel = styled(InputLabel)`
  && {
    font-size: 16px;
    line-height: 24px;
  }
`

const StyledSelect = styled(Select)`
  && {
    font-size: 16px;
    line-height: 24px;

    fieldset {
      border: 1px solid rgba(26, 25, 25, 0.2);
    }

    .MuiSelect-root {
      padding: 16px 14px;
    }

    &.Mui-focused {
      fieldset {
        border: 1px solid rgba(26, 25, 25, 0.4);
        box-shadow: 0 2px 6px 0 rgba(26, 25, 25, 0.2);
      }
    }

    .MuiSelect-select:focus {
      background: white;
    }

    &:hover {
      fieldset {
        border: 1px solid rgba(55, 111, 139, 0.6);
      }
    }
  }
`

const StyledMenuItem = styled(MenuItem)`
  && {
    font-size: 16px;
    line-height: 24px;
  }
`

export { StyledSelect, StyledInputLabel, StyledFormControl, StyledMenuItem }
