import { prop, map, values, filter } from '@soltalabs/ramda-extra'
import {
  createSelector,
  composeSelectors,
  createIsLoadingSelector,
} from '@soltalabs/react.redux-toolkit'
import moment from 'moment'

import { fetchUnassignedJobs, fetchNextUnassignedJobs } from './actions'

const getModuleState = createSelector('scheduler')

const getUnassignedJobsSortBy = composeSelectors(
  getModuleState,
  createSelector('unassignedJobsSortBy')
)

const getScheduledJobEntities = composeSelectors(
  getModuleState,
  createSelector('scheduledJobEntities')
)

const getScheduledJobs = composeSelectors(getScheduledJobEntities, (entities) =>
  values(entities)
)

const getScheduledJobsForCrewAndDay = composeSelectors(
  getScheduledJobs,
  (_, props) => props.crewId,
  (_, props) => props.day.value,
  (jobs, crewId, date) =>
    filter(
      (job) =>
        job.crewId === crewId &&
        moment(job.scheduledAt).isSameOrAfter(moment(date).startOf('day')) &&
        moment(job.scheduledAt).isSameOrBefore(moment(date).endOf('day')),
      jobs
    )
)

const getUnassignedJobEntities = composeSelectors(
  getModuleState,
  createSelector('unassignedJobEntities')
)

const getUnassignedJobIds = composeSelectors(
  getModuleState,
  createSelector('unassignedJobIds')
)

const getUnassignedJobs = composeSelectors(
  getUnassignedJobEntities,
  getUnassignedJobIds,
  (entities, ids) => map((id) => prop(id, entities), ids)
)

const getUnassignedJobsNextPageId = composeSelectors(
  getModuleState,
  createSelector('unassignedJobsNext')
)

const getIsFetchUnassignedJobsLoading = createIsLoadingSelector([fetchUnassignedJobs])

const getIsFetchNextUnassignedJobsLoading = createIsLoadingSelector([
  fetchNextUnassignedJobs,
])

export {
  getScheduledJobs,
  getScheduledJobsForCrewAndDay,
  getUnassignedJobs,
  getUnassignedJobsNextPageId,
  getUnassignedJobsSortBy,
  getIsFetchUnassignedJobsLoading,
  getIsFetchNextUnassignedJobsLoading,
}
