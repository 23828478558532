import { call, put, takeLatest } from 'redux-saga/effects'

import { ForecastService } from '../../api/forecast'

import { fetchForecasts } from './actions'

function* fetchForecastsSaga() {
  yield takeLatest(fetchForecasts.request, function*(action) {
    const { productId } = action.payload
    try {
      const { forecasts } = yield call(ForecastService.list, '', productId)

      yield put(fetchForecasts.succeeded(forecasts))
    } catch (error) {
      yield put(fetchForecasts.failed(error.message))
    }
  })
}

const forecastSagas = [fetchForecastsSaga()]

export { forecastSagas }
