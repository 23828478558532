import { prop, path, values } from '@soltalabs/ramda-extra'
import { createSelector } from 'reselect'

const selectProductsEntities = path(['products', 'entities'])

const selectProductsList = createSelector(selectProductsEntities, (entities) =>
  values(entities)
)

const selectProductForJob = createSelector(
  selectProductsEntities,
  (_, props) => path(['job', 'productId'], props),
  (productsEntities, productId) => prop(productId, productsEntities)
)

export { selectProductsEntities, selectProductsList, selectProductForJob }
