import './polyfills'
import React from 'react'
import ReactDOM from 'react-dom'

import { Root } from './root'

import './index.css'

const rootElement = document.getElementById('root')

if (rootElement) {
  ReactDOM.render(<Root />, rootElement)
}
