import { isNil, isEmpty, head } from '@soltalabs/ramda-extra'
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getCrews } from '../../../modules/crews'
import { fetchScheduledJobs } from '../../../modules/scheduler'

import { SchedulerPanelsSectionView } from './view'

function SchedulerPanelsSection() {
  const dispatch = useDispatch()
  const crews = useSelector(getCrews)

  useEffect(() => {
    if (!isNil(crews) && !isEmpty(crews)) {
      dispatch(fetchScheduledJobs.request(head(crews).id))
    }
  }, [dispatch, crews])

  return <SchedulerPanelsSectionView crews={crews} />
}

export { SchedulerPanelsSection }
