import { mergeDeepRight, omit, append, filter, equals } from '@soltalabs/ramda-extra'
import { createReducer } from '@soltalabs/react.redux-toolkit'

import { CREWS } from '../../constants/crews'

import {
  fetchCrews,
  createTempCrew,
  deleteTempCrew,
  createCrew,
  deleteCrew,
  watchCrew,
  unwatchCrew,
  addCrewMember,
  removeCrewMember,
  createCrewInvitation,
  deleteCrewInvitation,
} from './actions'

const INITIAL_STATE = Object.freeze({
  crewEntities: {},
  memberEntities: {},
})

const crewsReducer = createReducer(INITIAL_STATE, {
  [fetchCrews.succeeded]: (state, action) => ({
    ...state,
    crewEntities: action.payload.crews,
    memberEntities: action.payload.members,
  }),

  [createTempCrew]: (state) => ({
    ...state,
    crewEntities: mergeDeepRight(
      // purposefully invert so that temp crew appears at the top
      {
        [CREWS.TEMP_CREW_ID]: { id: CREWS.TEMP_CREW_ID },
      },
      state.crewEntities
    ),
  }),

  [deleteTempCrew]: (state) => ({
    ...state,
    crewEntities: omit([CREWS.TEMP_CREW_ID], state.crewEntities),
  }),

  [createCrew.succeeded]: (state, action) => ({
    ...state,
    crewEntities: omit(
      [CREWS.TEMP_CREW_ID],
      // purposefully invert so that new crew appears at the top
      mergeDeepRight(action.payload.crew, state.crewEntities)
    ),
  }),

  [deleteCrew.succeeded]: (state, action) => ({
    ...state,
    crewEntities: omit([action.payload.id], state.crewEntities),
  }),

  [watchCrew.succeeded]: (state, action) => ({
    ...state,
    crewEntities: mergeDeepRight(state.crewEntities, {
      [action.payload.id]: { tracked: true },
    }),
  }),

  [unwatchCrew.succeeded]: (state, action) => ({
    ...state,
    crewEntities: mergeDeepRight(state.crewEntities, {
      [action.payload.id]: { tracked: false },
    }),
  }),

  [addCrewMember.succeeded]: (state, action) => ({
    ...state,
    crewEntities: mergeDeepRight(state.crewEntities, action.payload.crew),
    memberEntities: mergeDeepRight(state.memberEntities, action.payload.member),
  }),

  [removeCrewMember.succeeded]: (state, action) => ({
    ...state,
    crewEntities: mergeDeepRight(state.crewEntities, action.payload.crew),
    memberEntities: omit([action.payload.memberId], state.memberEntities),
  }),

  [createCrewInvitation.succeeded]: (state, action) => ({
    ...state,
    crewEntities: mergeDeepRight(state.crewEntities, {
      [action.payload.crewId]: {
        invitations: append(
          action.payload.invitation,
          state.crewEntities[action.payload.crewId].invitations
        ),
      },
    }),
  }),

  [deleteCrewInvitation.succeeded]: (state, action) => ({
    ...state,
    crewEntities: mergeDeepRight(state.crewEntities, {
      [action.payload.crewId]: {
        invitations: filter(
          (invitation) => !equals(invitation.id, action.payload.invitationId),
          state.crewEntities[action.payload.crewId].invitations
        ),
      },
    }),
  }),
})

export { crewsReducer }
