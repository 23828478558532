import { createActionNamespace } from '@soltalabs/react.redux-toolkit'

const { createAsyncActionCreators } = createActionNamespace('organization')

const fetchUserInfo = createAsyncActionCreators('FETCH_USER_INFO', [
  undefined,
  (userInfo = {}) => ({ userInfo }),
  (errorMessage) => ({ errorMessage }),
])

const fetchOrganizationProfiles = createAsyncActionCreators(
  'FETCH_ORGANIZATION_PROFILES',
  [
    (query) => ({ query }),
    (profiles = {}) => ({ profiles }),
    (errorMessage) => ({ errorMessage }),
  ]
)

export { fetchUserInfo, fetchOrganizationProfiles }
