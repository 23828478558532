import EmailIcon from '@material-ui/icons/Email'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import React from 'react'
import Autosuggest from 'react-autosuggest'

import { COLORS } from '../../../constants/colors'
import { CREWS } from '../../../constants/crews'
import { deepMemo } from '../../../utils/react'

import {
  MemberSuggestionContainer,
  IconNameContainer,
  NameContainer,
  MemberName,
  MemberTitle,
  MemberEmail,
  AddMemberContainer,
} from './styles'

function MemberSuggestion({ type, item }) {
  return (
    <MemberSuggestionContainer>
      {type === CREWS.SUGGESTION_TYPES.MEMBER && (
        <>
          <IconNameContainer>
            <PersonAddIcon style={{ color: COLORS.THEME.SECONDARY }} />
            <NameContainer>
              <MemberName>
                {item.firstName} {item.lastName}
              </MemberName>
              <MemberTitle>{item.title}</MemberTitle>
            </NameContainer>
          </IconNameContainer>
          <MemberEmail>{item.email}</MemberEmail>
        </>
      )}
      {type === CREWS.SUGGESTION_TYPES.INVITE && (
        <>
          <NameContainer>
            <EmailIcon style={{ color: COLORS.THEME.SECONDARY }} />
            <MemberName>Send an invite to </MemberName>
          </NameContainer>
          <MemberEmail>{item.email}</MemberEmail>
        </>
      )}
    </MemberSuggestionContainer>
  )
}

function View({
  memberSuggestions,
  memberInputValue,
  getSuggestionValue,
  onMemberSuggestionSelected,
  onMemberInputChange,
  onMemberInputBlur,
  onSuggestionsFetchRequested,
}) {
  const suggestions = [
    ...memberSuggestions,
    {
      type: CREWS.SUGGESTION_TYPES.INVITE,
      item: {
        email: memberInputValue,
      },
    },
  ]
  const inputProps = {
    value: memberInputValue,
    onChange: onMemberInputChange,
    onBlur: onMemberInputBlur,
    placeholder: 'Search to add or invite member',
  }

  return (
    <AddMemberContainer>
      <Autosuggest
        highlightFirstSuggestion
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={() => {}}
        renderSuggestion={MemberSuggestion}
        getSuggestionValue={getSuggestionValue}
        onSuggestionSelected={onMemberSuggestionSelected}
        inputProps={inputProps}
      />
    </AddMemberContainer>
  )
}

const AddMemberRowView = deepMemo(View)

export { AddMemberRowView }
