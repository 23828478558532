import loadable from 'react-loadable'

import Spinner from '../components/common/Spinner'

const customLoadable = (options) => {
  return loadable({
    loading: Spinner,
    ...options,
  })
}

export default customLoadable
