import React from 'react'

import styled from 'styled-components'
import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { AssessmentTooltipContents } from '../AssessmentTooltipContents'
import { getColorFromAssessmentScore } from '../../../utils/assessment'

const StyledTooltip = withStyles({
  tooltip: {
    padding: '4px',
    maxWidth: 256,
    fontFamily: 'Nunito Sans',
    fontSize: '12px',
    fontWeight: 700,
    backgroundColor: 'rgba(0, 0, 0, 0.92)',
    color: 'white',
    borderRadius: '5px',
  },
})(Tooltip)

const ColorBar = styled.div`
  width: 6px;
  height: 12px;
  background: ${(props) => props.color};

  border-left: 1px solid rgba(26, 25, 25, 0.2);
  border-right: 1px solid rgba(26, 25, 25, 0.2);
  &:hover {
    cursor: pointer;
    height: 18px;
    margin-top: -10px;
    border-left: 1px solid rgba(55, 111, 139, 0.6);
    border-right: 1px solid rgba(55, 111, 139, 0.6);
  }
`

const WrappedColorBar = React.forwardRef((props, ref) => {
  //  Spread the props to the underlying DOM element.
  return <ColorBar {...props} ref={ref} />
})

function Assessment({ product, assessment, onMouseEnter, onMouseLeave, ...props }) {
  return (
    <StyledTooltip
      title={<AssessmentTooltipContents product={product} assessment={assessment} />}
      placement="top"
      {...props}
    >
      <WrappedColorBar
        color={getColorFromAssessmentScore(assessment.score)}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      />
    </StyledTooltip>
  )
}

export { Assessment }
