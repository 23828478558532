import { Auth } from 'aws-amplify'
import fetch from 'isomorphic-fetch'

import { PROFILE_API_ENDPOINT } from '../../utils/env'

const signUpApiRequest = ({ values }) =>
  fetch(`${PROFILE_API_ENDPOINT}/profiles`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify(values),
  })

const signOutApiRequest = () => Auth.signOut()

export { signUpApiRequest, signOutApiRequest }
