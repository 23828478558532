import { call, put, takeLatest } from 'redux-saga/effects'

import {
  fetchJobs,
  fetchNextJobs,
  updateSingleJob,
  updateMultipleJobs,
  reorderJobs,
  addJob,
  deleteJob,
} from './actions'

import { JobService } from '../../api/job'

function* fetchJobsSaga() {
  yield takeLatest(fetchJobs.request, function*(action) {
    const { sortBy, next } = action.payload
    try {
      const { newNext, newSortBy, jobs, ids } = yield call(
        JobService.list,
        sortBy,
        next
      )

      yield put(fetchJobs.succeeded(newSortBy, newNext, jobs, ids))
    } catch (error) {
      yield put(fetchJobs.failed(error.message))
    }
  })
}

function* fetchNextJobsSaga() {
  yield takeLatest(fetchNextJobs.request, function*(action) {
    const { sortBy, next } = action.payload
    try {
      const { newNext, newSortBy, jobs, ids } = yield call(
        JobService.list,
        sortBy,
        next
      )

      yield put(fetchNextJobs.succeeded(newSortBy, newNext, jobs, ids))
    } catch (error) {
      yield put(fetchNextJobs.failed(error.message))
    }
  })
}

function* updateSingleJobSaga() {
  yield takeLatest(updateSingleJob.request, function*(action) {
    const { jobUpdate } = action.payload
    try {
      const job = yield call(JobService.update, jobUpdate)

      yield put(updateSingleJob.succeeded(job))
    } catch (error) {
      yield put(updateSingleJob.failed(error.message))
    }
  })
}

function* updateMultipleJobsSaga() {
  yield takeLatest(updateMultipleJobs.request, function*(action) {
    const { jobUpdates } = action.payload
    try {
      const jobs = yield call(JobService.bulkUpdate, jobUpdates)

      yield put(updateMultipleJobs.succeeded(jobs))
    } catch (error) {
      yield put(updateMultipleJobs.failed(error.message))
    }
  })
}

function* reorderJobsSaga() {
  yield takeLatest(reorderJobs, function*(action) {
    const { source, destination } = action.payload
    try {
      const jobs = yield call(JobService.bulkUpdate, [source, destination])

      yield put(updateMultipleJobs.succeeded(jobs))
    } catch (error) {
      yield put(updateMultipleJobs.failed(error.message))
    }
  })
}

function* addJobSaga() {
  yield takeLatest(addJob.request, function*(action) {
    const { jobValues, sortBy } = action.payload
    try {
      const job = yield call(JobService.add, jobValues)

      yield put(addJob.succeeded(job))
      yield put(fetchJobs.request(sortBy))
    } catch (error) {
      yield put(addJob.failed(error.message))
    }
  })
}

function* deleteJobSaga() {
  yield takeLatest(deleteJob.request, function*(action) {
    const { id } = action.payload
    try {
      const jobId = yield call(JobService.delete, id)

      yield put(deleteJob.succeeded(jobId))
    } catch (error) {
      yield put(deleteJob.failed(error.message))
    }
  })
}

const jobSagas = [
  fetchJobsSaga(),
  fetchNextJobsSaga(),
  updateSingleJobSaga(),
  updateMultipleJobsSaga(),
  reorderJobsSaga(),
  addJobSaga(),
  deleteJobSaga(),
]

export { jobSagas }
