import { createActionNamespace } from '@soltalabs/react.redux-toolkit'

const { createAsyncActionCreators } = createActionNamespace('audit')

const recordAuditEvent = createAsyncActionCreators('RECORD_AUDIT_EVENT', [
  (event) => ({ event }),
  undefined,
  (errorMessage) => ({ errorMessage }),
])

export { recordAuditEvent }
