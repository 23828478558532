import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import moment from 'moment'
import React from 'react'

import { deepMemo } from '../../../utils/react'
import { ScheduledJobList } from '../ScheduledJobList'

import {
  CrewExpansionPanel,
  CrewExpansionSummary,
  CrewName,
  CrewExpansionDetails,
} from './styles'

const DAYS = [
  {
    name: 'Yesterday',
    value: moment
      .utc()
      .subtract(1, 'days')
      .startOf('day'),
  },
  {
    name: 'Today',
    value: moment.utc().startOf('day'),
  },
  {
    name: 'Tomorrow',
    value: moment
      .utc()
      .add(1, 'days')
      .startOf('day'),
  },
  {
    name: 'Day After',
    value: moment
      .utc()
      .add(2, 'days')
      .startOf('day'),
  },
]

function View({
  scrollRef,
  crew,
  defaultExpanded,
  onExpansionPanelChange,
  onMouseDown,
  onMouseUp,
  onMouseMove,
}) {
  return (
    <CrewExpansionPanel
      key={crew.id}
      onChange={onExpansionPanelChange}
      defaultExpanded={defaultExpanded}
    >
      <CrewExpansionSummary expandIcon={<ExpandMoreIcon />}>
        <CrewName>{crew.name}</CrewName>
      </CrewExpansionSummary>
      <CrewExpansionDetails ref={scrollRef}>
        {DAYS.map((day) => (
          <ScheduledJobList
            key={day.name}
            day={day}
            crewId={crew.id}
            onMouseDown={onMouseDown}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
          />
        ))}
      </CrewExpansionDetails>
    </CrewExpansionPanel>
  )
}

const SchedulerPanelView = deepMemo(View)

export { SchedulerPanelView }
