import { Container } from '@material-ui/core'
import styled from 'styled-components'

const FooterContainer = styled.div`
  bottom: 0;
  height: 62px;
  background-color: white;
  box-shadow: none;
  position: fixed;
  width: 100%;
  z-index: 2;
`

const FooterContent = styled(Container)`
  && {
    border-top: 1px solid rgba(26, 25, 25, 0.2);
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-width: 1440px;

    @media (min-width: 960px) {
      padding-left: 112px;
      padding-right: 112px;
    }
    @media (min-width: 600px) {
      padding-left: 104px;
      padding-right: 104px;
    }
  }
`

const CopyRightLabel = styled.div`
  color: #1a1919;

  font-size: 12px;
  line-height: 18px;
`

export { FooterContainer, FooterContent, CopyRightLabel }
