import { takeLatest, call, put } from 'redux-saga/effects'

import { OrganizationService } from '../../api/organization'

import { fetchUserInfo, fetchOrganizationProfiles } from './actions'

function* fetchUserInfoSaga() {
  yield takeLatest(fetchUserInfo.request, function*() {
    try {
      const userInfo = yield call(OrganizationService.getUserInfo)

      yield put(fetchUserInfo.succeeded(userInfo))
    } catch (error) {
      yield put(fetchUserInfo.failed(error.message))
    }
  })
}

function* fetchOrganizationProfilesSaga() {
  yield takeLatest(fetchOrganizationProfiles.request, function*(action) {
    const { query } = action.payload

    try {
      const profiles = yield call(OrganizationService.listProfiles, query)

      yield put(fetchOrganizationProfiles.succeeded(profiles))
    } catch (error) {
      yield put(fetchOrganizationProfiles.failed(error.message))
    }
  })
}

const organizationSagas = [fetchUserInfoSaga(), fetchOrganizationProfilesSaga()]

export { organizationSagas }
