import { takeLatest, call, put } from 'redux-saga/effects'

import { JobService } from '../../api/job'

import {
  fetchScheduledJobs,
  fetchUnassignedJobs,
  fetchNextUnassignedJobs,
  updateSingleUnassignedJob,
  updateMultipleUnassignedJobs,
  scheduleJob,
  unscheduleJob,
  deleteUnassignedJob,
} from './actions'

function* fetchScheduledJobsSaga() {
  yield takeLatest(fetchScheduledJobs.request, function*(action) {
    const { crewId } = action.payload

    try {
      const jobs = yield call(JobService.listScheduled, crewId)

      yield put(fetchScheduledJobs.succeeded(jobs))
    } catch (error) {
      yield put(fetchScheduledJobs.failed(error.message))
    }
  })
}

function* fetchUnassignedJobsSaga() {
  yield takeLatest(fetchUnassignedJobs.request, function*(action) {
    const { sortBy, next } = action.payload

    try {
      const { newNext, newSortBy, jobs, ids } = yield call(
        JobService.listUnassigned,
        sortBy,
        next
      )

      yield put(fetchUnassignedJobs.succeeded(newSortBy, newNext, jobs, ids))
    } catch (error) {
      yield put(fetchUnassignedJobs.failed(error.message))
    }
  })
}

function* fetchNextUnassignedJobsSaga() {
  yield takeLatest(fetchNextUnassignedJobs.request, function*(action) {
    const { sortBy, next } = action.payload

    try {
      const { newNext, newSortBy, jobs, ids } = yield call(
        JobService.listUnassigned,
        sortBy,
        next
      )

      yield put(fetchNextUnassignedJobs.succeeded(newSortBy, newNext, jobs, ids))
    } catch (error) {
      yield put(fetchNextUnassignedJobs.failed(error.message))
    }
  })
}

function* updateSingleUnassignedJobSaga() {
  yield takeLatest(updateSingleUnassignedJob.request, function*(action) {
    const { jobUpdate } = action.payload
    try {
      const job = yield call(JobService.update, jobUpdate)

      yield put(updateSingleUnassignedJob.succeeded(job))
    } catch (error) {
      yield put(updateSingleUnassignedJob.failed(error.message))
    }
  })
}

function* updateMultipleUnassignedJobsSaga() {
  yield takeLatest(updateMultipleUnassignedJobs.request, function*(action) {
    const { jobUpdates } = action.payload
    try {
      const jobs = yield call(JobService.bulkUpdate, jobUpdates)

      yield put(updateMultipleUnassignedJobs.succeeded(jobs))
    } catch (error) {
      yield put(updateMultipleUnassignedJobs.failed(error.message))
    }
  })
}

function* scheduleJobSaga() {
  yield takeLatest(scheduleJob.request, function*(action) {
    const { jobId, crewId, scheduledAt } = action.payload
    try {
      const job = yield call(JobService.schedule, jobId, crewId, scheduledAt)

      yield put(scheduleJob.succeeded(job))
    } catch (error) {
      yield put(scheduleJob.failed(error.message))
    }
  })
}

function* unscheduleJobSaga() {
  yield takeLatest(unscheduleJob.request, function*(action) {
    const { id } = action.payload
    try {
      const job = yield call(JobService.schedule, id, '')

      yield put(unscheduleJob.succeeded(job))
    } catch (error) {
      yield put(unscheduleJob.failed(error.message))
    }
  })
}

function* deleteUnassignedJobSaga() {
  yield takeLatest(deleteUnassignedJob.request, function*(action) {
    const { id } = action.payload
    try {
      const jobId = yield call(JobService.delete, id)

      yield put(deleteUnassignedJob.succeeded(jobId))
    } catch (error) {
      yield put(deleteUnassignedJob.failed(error.message))
    }
  })
}

const schedulerSagas = [
  fetchScheduledJobsSaga(),
  fetchUnassignedJobsSaga(),
  fetchNextUnassignedJobsSaga(),
  updateSingleUnassignedJobSaga(),
  updateMultipleUnassignedJobsSaga(),
  scheduleJobSaga(),
  unscheduleJobSaga(),
  deleteUnassignedJobSaga(),
]

export { schedulerSagas }
