import React from 'react'

import { deepMemo } from '../../../utils/react'

import {
  FormContainer,
  FormSection,
  InputSection,
  FieldContainer,
  InputLabel,
  SignUpTitle,
  SignUpButton,
  StyledInput,
  FooterSection,
} from './styles'

const View = ({
  onSignUp,
  firstName,
  lastName,
  password,
  onFirstNameValueChange,
  onLastNameValueChange,
  onPasswordValueChange,
}) => (
  <FormContainer>
    <FormSection>
      <SignUpTitle>Create your account</SignUpTitle>
      <form onSubmit={onSignUp}>
        <InputSection>
          <FieldContainer>
            <InputLabel>First Name *</InputLabel>
            <StyledInput
              type="text"
              placeholder="Enter your first name"
              value={firstName}
              onChange={onFirstNameValueChange}
            />
          </FieldContainer>
          <FieldContainer>
            <InputLabel>Last Name *</InputLabel>
            <StyledInput
              type="text"
              placeholder="Enter your last name"
              value={lastName}
              onChange={onLastNameValueChange}
            />
          </FieldContainer>
          <FieldContainer>
            <InputLabel>Password *</InputLabel>
            <StyledInput
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={onPasswordValueChange}
            />
          </FieldContainer>
        </InputSection>

        <FooterSection>
          <SignUpButton type="submit" value="ACCEPT INVITATION" />
        </FooterSection>
      </form>
    </FormSection>
  </FormContainer>
)

const AcceptInvitationPageView = deepMemo(View)

export { AcceptInvitationPageView }
