import React from 'react'
import { deepMemo } from '../../../utils/react'
import { useHistory, useLocation } from 'react-router-dom'
import { Can } from '@casl/react'
import { defineRavensdownRules } from '../../../abilities/common'

import { Tabs, Tab } from './styles'

function NavigationTabs({ isManager, userInfo }) {
  const ravensdownAbilities = defineRavensdownRules(userInfo)
  const history = useHistory()
  const location = useLocation()
  const tabIndex = getTabIndexFromPath(location.pathname)

  function handleTabClick(path) {
    history.push(path)
  }

  function handleOverviewClick() {
    handleTabClick('/')
  }

  function handleScheduleClick() {
    handleTabClick('/scheduler')
  }

  function handleCrewsClick() {
    handleTabClick('/crews')
  }

  return (
    <Can not I="manage" on="Ravensdown" ability={ravensdownAbilities}>
      <Tabs value={tabIndex}>
        <Tab label="Overview" onClick={handleOverviewClick} />
        {isManager && <Tab label="Schedule" onClick={handleScheduleClick} />}
        {isManager && <Tab label="Crews" onClick={handleCrewsClick} />}
      </Tabs>
    </Can>
  )
}

function getTabIndexFromPath(path) {
  if (path.match(/scheduler/)) {
    return 1
  }

  if (path.match(/crews/)) {
    return 2
  }

  return 0
}

const MemoizedNavigationTabs = deepMemo(NavigationTabs)

export { MemoizedNavigationTabs as NavigationTabs }
