import { ButtonBase, TextField } from '@material-ui/core'
import { isNil } from '@soltalabs/ramda-extra'
import React, { useState, forwardRef } from 'react'
import styled from 'styled-components'

import { ReactComponent as CrossIcon } from '../../assets/cross.svg'
import { COLORS } from '../../constants/colors'
import { useSupportModule } from '../../modules/support'
import Spinner from '../common/Spinner'

const Container = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  margin: 'auto',

  display: 'flex',
  flexDirection: 'column',

  width: '400px',
  height: '400px',
  backgroundColor: 'white',
})

const Header = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginBottom: '16px',
})

const Title = styled.h1({
  flex: 1,
  margin: '16px 0 0 0',
  fontSize: '16px',
  marginLeft: '16px',
})

const CancelButton = styled(ButtonBase)({
  alignSelf: 'flex-start',
  width: '32px',
  height: '32px',
})

const TextArea = styled(TextField)({
  width: '100%',
})

const OnSuccessMessage = styled.p({
  fontSize: '24px',
  margin: 0,
  marginBottom: '16px',
  padding: '0 32px',
  textAlign: 'center',
})

const SolidButton = styled(ButtonBase)`
  && {
    height: 32px;
    width: 96px;

    background-color: ${COLORS.THEME.PRIMARY};
    border-radius: 3px;

    font-size: 14px;
    font-weight: 700;
    color: white;
  }
`

function SupportForm({ forwardedRef, ...props }) {
  const {
    dispatchSubmitSupportTicket,
    dispatchHideSupportModal,
    isSubmittingSupportTicket,
    submitSupportTicketErrorMessage,
  } = useSupportModule()
  const [message, setMessage] = useState('')
  const [hasSubmitted, setHasSubmitted] = useState(false)

  function handleSubmit() {
    dispatchSubmitSupportTicket(message)
    setHasSubmitted(true)
  }

  if (isSubmittingSupportTicket) {
    return (
      <Container
        {...props}
        ref={forwardedRef}
        style={{ alignItems: 'center', justifyContent: 'center' }}
      >
        <Spinner />
      </Container>
    )
  }

  return (
    <Container {...props} ref={forwardedRef}>
      {!shouldDisplaySuccessMessage(
        hasSubmitted,
        isSubmittingSupportTicket,
        submitSupportTicketErrorMessage
      ) ? (
        <>
          <Header>
            <Title>Support</Title>
            <CancelButton onClick={dispatchHideSupportModal}>
              <CrossIcon fill="#CCC" width={20} height={20} />
            </CancelButton>
          </Header>

          <div style={{ padding: '0 16px' }}>
            <TextArea
              multiline
              rows={12}
              placeholder="Describe your issue"
              variant="outlined"
              color="primary"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
          </div>

          <SolidButton
            onClick={handleSubmit}
            style={{ alignSelf: 'flex-end', margin: '16px 16px 0 0' }}
          >
            SEND
          </SolidButton>
        </>
      ) : (
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <OnSuccessMessage>
            Support request successful. We will respond to your inquiry via e-mail as
            soon as possible.
          </OnSuccessMessage>

          <SolidButton
            style={{ alignSelf: 'center' }}
            onClick={dispatchHideSupportModal}
          >
            OK
          </SolidButton>
        </div>
      )}
    </Container>
  )
}

function shouldDisplaySuccessMessage(
  hasSubmitted,
  isSubmittingSupportTicket,
  submitSupportTicketErrorMessage
) {
  if (!hasSubmitted) {
    return false
  }

  if (isSubmittingSupportTicket) {
    return false
  }

  if (!isNil(submitSupportTicketErrorMessage)) {
    return false
  }

  return true
}

const ForwardedSupportForm = forwardRef((props, ref) => (
  <SupportForm {...props} forwardedRef={ref} />
))

export { ForwardedSupportForm as SupportForm }
