import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { removeCrewMember } from '../../../modules/crews'

import { CrewMemberRowView } from './view'

function CrewMemberRow({ crewId, member }) {
  const dispatch = useDispatch()
  const [showRemoveButton, setShowRemoveButton] = useState(false)

  function onMouseEnter() {
    setShowRemoveButton(true)
  }

  function onMouseLeave() {
    setShowRemoveButton(false)
  }

  function onRemoveMemberButtonClick() {
    dispatch(removeCrewMember.request(crewId, member.id))
  }

  return (
    <CrewMemberRowView
      member={member}
      showRemoveButton={showRemoveButton}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onRemoveMemberButtonClick={onRemoveMemberButtonClick}
    />
  )
}

export { CrewMemberRow }
