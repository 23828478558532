import AddIcon from '@material-ui/icons/Add'
import { isEmpty, path, equals } from '@soltalabs/ramda-extra'
import React from 'react'

import { CREWS } from '../../../constants/crews'
import { deepMemo } from '../../../utils/react'
import { EmptyListPrompt, StyledCircularProgress } from '../../common/styles'
import { CrewPanel } from '../CrewPanel'

import {
  PageContainer,
  CrewPanelsContainer,
  AddCrewButtonsContainer,
  SubmitButton,
  CancelButton,
  AddCrewButton,
} from './styles'

function View({
  crews,
  tempCrewRef,
  onAddCrewButtonClick,
  onSubmitButtonClick,
  onCancelButtonClick,
  isFetchCrewsLoading,
}) {
  const isAddingCrew = equals(path([0, 'id'], crews), CREWS.TEMP_CREW_ID)
  return (
    <PageContainer maxWidth="lg">
      <AddCrewButtonsContainer>
        {isAddingCrew ? (
          <>
            <SubmitButton
              variant="contained"
              color="primary"
              size="large"
              onClick={onSubmitButtonClick}
            >
              Submit
            </SubmitButton>
            <CancelButton color="secondary" size="large" onClick={onCancelButtonClick}>
              Cancel
            </CancelButton>
          </>
        ) : (
          <AddCrewButton
            variant="contained"
            color="primary"
            size="large"
            startIcon={<AddIcon />}
            onClick={onAddCrewButtonClick}
          >
            Add Crew
          </AddCrewButton>
        )}
      </AddCrewButtonsContainer>
      <CrewPanelsContainer>
        {isFetchCrewsLoading && <StyledCircularProgress />}
        {isEmpty(crews) && !isFetchCrewsLoading ? (
          <EmptyListPrompt>No crews available</EmptyListPrompt>
        ) : (
          crews.map((crew) => (
            <CrewPanel
              ref={tempCrewRef}
              key={crew.id}
              crew={crew}
              onSubmit={onSubmitButtonClick}
            />
          ))
        )}
      </CrewPanelsContainer>
    </PageContainer>
  )
}

const CrewsPageView = deepMemo(View)

export { CrewsPageView }
