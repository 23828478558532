import { combineReducers } from 'redux'
import { connectRouter as createRouterReducer } from 'connected-react-router'

import { asyncModule } from '@soltalabs/react.redux-toolkit'
import { authReducer } from '../modules/auth'
import { jobsReducer } from '../modules/job'
import { crewsReducer } from '../modules/crews'
import { organizationReducer } from '../modules/organization'
import { productsReducer } from '../modules/product'
import { schedulerReducer } from '../modules/scheduler'
import { forecastsReducer } from '../modules/forecast'
import { supportReducer } from '../modules/support'

const createRootReducer = (history) =>
  combineReducers({
    ...asyncModule,
    auth: authReducer,
    crews: crewsReducer,
    organization: organizationReducer,
    products: productsReducer,
    jobs: jobsReducer,
    scheduler: schedulerReducer,
    forecasts: forecastsReducer,
    support: supportReducer,
    router: createRouterReducer(history),
  })

export { createRootReducer }
