import React, { Component } from 'react'

import { redux } from './redux'
import { CrewPanelView } from './view'

class Container extends Component {
  state = {
    showDeleteButton: false,
    isDeleting: false,
  }

  onCrewNameInputChange = (e) => {
    this.props.tempCrewRef.current = e.target.value
  }

  onCrewNameInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.props.onSubmit()
    }
  }

  onMouseEnterPanel = () => {
    this.setState({ showDeleteButton: true })
  }

  onMouseLeavePanel = () => {
    this.setState({ showDeleteButton: false })
  }

  onWatchButtonClick = (e) => {
    e.stopPropagation()
    const { crew, dispatchWatchCrew } = this.props
    dispatchWatchCrew(crew.id)
  }

  onUnwatchButtonClick = (e) => {
    e.stopPropagation()
    const { crew, dispatchUnwatchCrew } = this.props
    dispatchUnwatchCrew(crew.id)
  }

  onDeleteButtonClick = (e) => {
    e.stopPropagation()
    this.setState({ isDeleting: true })
  }

  onConfirmButtonClick = (e) => {
    e.stopPropagation()
    const { crew, dispatchDeleteCrew } = this.props
    dispatchDeleteCrew(crew.id)
  }

  onUndoButtonClick = (e) => {
    e.stopPropagation()
    this.setState({ isDeleting: false })
  }

  render() {
    return (
      <CrewPanelView
        {...this.props}
        {...this.state}
        onCrewNameInputChange={this.onCrewNameInputChange}
        onCrewNameInputKeyPress={this.onCrewNameInputKeyPress}
        onMouseEnterPanel={this.onMouseEnterPanel}
        onMouseLeavePanel={this.onMouseLeavePanel}
        onUnwatchButtonClick={this.onUnwatchButtonClick}
        onWatchButtonClick={this.onWatchButtonClick}
        onDeleteButtonClick={this.onDeleteButtonClick}
        onConfirmButtonClick={this.onConfirmButtonClick}
        onUndoButtonClick={this.onUndoButtonClick}
      />
    )
  }
}

const CrewPanel = redux(Container)

export { CrewPanel }
