import { createActionNamespace } from '@soltalabs/react.redux-toolkit'

const { createActionCreator, createAsyncActionCreators } = createActionNamespace('auth')

const initializeCurrentUser = createAsyncActionCreators('INITIALIZE_CURRENT_USER', [
  undefined,
  (currentUser) => ({ currentUser }),
  (errorMessage) => ({ errorMessage }),
])

const currentUserChanged = createActionCreator(
  'CURRENT_USER_CHANGED',
  (currentUser) => ({
    currentUser,
  })
)

const signOut = createAsyncActionCreators('SIGN_OUT', [
  undefined,
  undefined,
  (errorMessage) => ({ errorMessage }),
])

export { initializeCurrentUser, currentUserChanged, signOut }
