import { createReducer } from '@soltalabs/react.redux-toolkit'
import { showSupportModal, hideSupportModal } from './actions'

const INITIAL_STATE = Object.freeze({
  showModal: false,
})

const supportReducer = createReducer(INITIAL_STATE, {
  [showSupportModal]: (state) => ({
    ...state,
    showModal: true,
  }),

  [hideSupportModal]: (state) => ({
    ...state,
    showModal: false,
  }),
})

export { supportReducer }
