import React from 'react'

import { redux } from './redux'
import { HeaderView } from './view'

class HeaderContainer extends React.Component {
  componentWillMount() {
    this.props.dispatchFetchUserInfo()
    this.props.dispatchGetProducts()
  }

  onSignOut = () => {
    this.props.dispatchSignOut()
  }

  render() {
    return <HeaderView {...this.props} onSignOut={this.onSignOut} />
  }
}

const Header = redux(HeaderContainer)

export { Header }
