import { isEmpty } from '@soltalabs/ramda-extra'
import React from 'react'

import { deepMemo } from '../../../utils/react'
import { SchedulerPanel } from '../SchedulerPanel'

import { Container, EmptyListPrompt } from './styles'

function View({ crews }) {
  return (
    <Container>
      {isEmpty(crews) ? (
        <EmptyListPrompt>No crews available</EmptyListPrompt>
      ) : (
        crews.map((crew, index) => (
          <SchedulerPanel key={crew.id} crew={crew} defaultExpanded={index === 0} />
        ))
      )}
    </Container>
  )
}

const SchedulerPanelsSectionView = deepMemo(View)

export { SchedulerPanelsSectionView }
