import { API } from 'aws-amplify'

import { RISK_ASSESSMENT_API_NAME } from '../../utils/env'

const getProductsApiRequest = () => {
  const apiName = RISK_ASSESSMENT_API_NAME
  const path = '/products'

  return API.get(apiName, path)
}

export { getProductsApiRequest }
