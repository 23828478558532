import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

import { COLORS } from '../../../constants/colors'
import { CREWS } from '../../../constants/crews'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-top: 1px solid rgba(26, 25, 25, 0.2);
`

const InvitationInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
`

const InvitationStatus = styled.div`
  font-size: 16px;
  width: 15%;
  padding-left: 20px;
  color: ${(props) =>
    props.status === CREWS.INVITATION_STATUS.PENDING ? 'blue' : 'black'};
`

const InvitationEmail = styled.a`
  color: ${COLORS.THEME.PRIMARY};
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
`

const Divider = styled.div`
  width: 1px;
  height: 36px;
  background: rgba(26, 25, 25, 0.2);
  margin: 0 40px;
`

const DeleteInvitationButton = styled(IconButton)`
  && {
    padding: 0;
  }
`

export {
  Container,
  InvitationInfoContainer,
  InvitationStatus,
  InvitationEmail,
  Divider,
  DeleteInvitationButton,
}
