import { Link as LinkBase } from 'react-router-dom'
import styled from 'styled-components'

import { COLORS } from '../../../constants/colors'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h1`
  margin: 32px 0;
  font-size: 36px;
  font-weight: 700;
  color: ${COLORS.THEME.SECONDARY};
`

const Link = styled(LinkBase)`
  margin: 36px 0;
  padding: 12px 24px;

  border-radius: 24px;
  color: white;
  text-decoration: none;
  font-weight: 800;
  letter-spacing: 0.5px;

  background-color: ${COLORS.THEME.PRIMARY};
`

const Text = styled.p`
  margin: 0;
  max-width: 320px;
  text-align: center;
  font-size: 20px;
`

export { Container, Title, Link, Text }
