import { useDispatch, useSelector } from 'react-redux'

import { submitSupportTicket, showSupportModal, hideSupportModal } from './actions'
import {
  getShowModal,
  getIsSubmittingSupportTicket,
  getSubmitSupportTicketErrorMessage,
} from './selectors'

function useSupportModule() {
  const dispatch = useDispatch()

  function dispatchSubmitSupportTicket(message) {
    dispatch(submitSupportTicket.request(message))
  }

  function dispatchShowSupportModal() {
    dispatch(showSupportModal())
  }

  function dispatchHideSupportModal() {
    dispatch(hideSupportModal())
  }

  const showModal = useSelector(getShowModal)
  const isSubmittingSupportTicket = useSelector(getIsSubmittingSupportTicket)
  const submitSupportTicketErrorMessage = useSelector(
    getSubmitSupportTicketErrorMessage
  )

  return {
    dispatchSubmitSupportTicket,
    dispatchShowSupportModal,
    dispatchHideSupportModal,
    showModal,
    isSubmittingSupportTicket,
    submitSupportTicketErrorMessage,
  }
}

export { useSupportModule }
