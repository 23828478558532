import { takeLatest, call, put } from 'redux-saga/effects'

import { OrganizationService } from '../../api/organization'

import { submitSupportTicket } from './actions'

function* submitSupportTicketSaga() {
  yield takeLatest(submitSupportTicket.request, function*(action) {
    const { message } = action.payload

    try {
      yield call(OrganizationService.submitSupportTicket, message)
      yield put(submitSupportTicket.succeeded())
    } catch (error) {
      yield put(submitSupportTicket.failed(error.message))
    }
  })
}

const supportSagas = [submitSupportTicketSaga()]

export { supportSagas }
