import React from 'react'
import { connect } from 'react-redux'

import { watchCrew, unwatchCrew, deleteCrew } from '../../../modules/crews'

const mapDispatchToProps = (dispatch) => ({
  dispatchWatchCrew: (id) => dispatch(watchCrew.request(id)),
  dispatchUnwatchCrew: (id) => dispatch(unwatchCrew.request(id)),
  dispatchDeleteCrew: (id) => dispatch(deleteCrew.request(id)),
})

const redux = (Component) => {
  const ConnectedComponent = connect(null, mapDispatchToProps)(Component)

  return React.forwardRef((props, ref) => (
    <ConnectedComponent {...props} tempCrewRef={ref} />
  ))
}

export { redux }
