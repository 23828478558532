import {
  Button,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'
import styled from 'styled-components'

import { COLORS } from '../../../constants/colors'

const EmptyListPrompt = styled.div`
  margin: 20px auto;
  font-size: 24px;
  color: #a1a1a1;
`

const CrewExpansionPanel = styled(ExpansionPanel)`
  && {
    box-shadow: 0 2px 6px 0 rgba(26, 25, 25, 0.2);
    background: white;
  }
`

const CrewExpansionSummary = styled(ExpansionPanelSummary)`
  && {
    .MuiExpansionPanelSummary-content {
      align-items: center;
      justify-content: space-between;
    }
  }
`

const CrewNameInput = styled.input`
  width: 100%;
  height: 32px;
  border: none;
  outline: none;
  color: ${COLORS.THEME.SECONDARY};
  font-size: 24px;
  font-weight: 800;
  padding: 5px 0;
  min-height: 32px;
  &::placeholder {
    opacity: 0.5;
  }
`

const CrewName = styled.span`
  color: ${COLORS.THEME.SECONDARY};
  font-size: 24px;
  font-weight: 800;
  padding: 5px 0;
  min-height: 32px;
`

const CrewExpansionDetails = styled(ExpansionPanelDetails)`
  && {
    display: flex;
    flex-direction: column;
    padding: 0px 24px 10px;
    overflow-y: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`

const ButtonsContainer = styled.div``

const ActionButton = styled(Button)`
  && {
    margin: 0 10px;
  }
`

export {
  EmptyListPrompt,
  CrewExpansionPanel,
  CrewExpansionSummary,
  CrewNameInput,
  CrewName,
  CrewExpansionDetails,
  ButtonsContainer,
  ActionButton,
}
