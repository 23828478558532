import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'

import { fetchScheduledJobs } from '../../../modules/scheduler'

import { SchedulerPanelView } from './view'

function SchedulerPanel({ crew, defaultExpanded }) {
  const scrollRef = useRef(null)
  const dispatch = useDispatch()

  const [clientX, setClientX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [isScrolling, setIsScrolling] = useState(false)

  function onExpansionPanelChange(e, expanded) {
    if (expanded) {
      dispatch(fetchScheduledJobs.request(crew.id))
    }
  }

  function onMouseDown(e) {
    setIsScrolling(true)
    setClientX(e.clientX)
    setScrollLeft(scrollRef.current.scrollLeft)
  }

  function onMouseUp() {
    setIsScrolling(false)
  }

  function onMouseMove(e) {
    if (isScrolling) {
      scrollRef.current.scrollLeft = scrollLeft + clientX - e.clientX
    }
  }

  return (
    <SchedulerPanelView
      scrollRef={scrollRef}
      crew={crew}
      defaultExpanded={defaultExpanded}
      onExpansionPanelChange={onExpansionPanelChange}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    />
  )
}

export { SchedulerPanel }
