import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'
import styled from 'styled-components'

const CrewExpansionPanel = styled(ExpansionPanel)`
  && {
    box-shadow: none;
    overflow: hidden;
  }
`

const CrewExpansionSummary = styled(ExpansionPanelSummary)`
  && {
    padding: 0 12px 0 0;
  }
`

const CrewName = styled.span`
  color: rgba(26, 25, 25, 0.6);
  font-size: 24px;
  font-weight: 800;
  padding: 0;
`

const CrewExpansionDetails = styled(ExpansionPanelDetails)`
  && {
    padding: 0;
    min-height: 100%;
    overflow-x: scroll;
  }
`

export { CrewExpansionPanel, CrewExpansionSummary, CrewName, CrewExpansionDetails }
