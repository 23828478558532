import { createReducer } from '@soltalabs/react.redux-toolkit'
import { fetchUserInfo, fetchOrganizationProfiles } from './actions'

const INITIAL_STATE = Object.freeze({
  userInfo: {},
  profileEntities: {},
})

const organizationReducer = createReducer(INITIAL_STATE, {
  [fetchUserInfo.succeeded]: (state, action) => ({
    ...state,
    userInfo: action.payload.userInfo,
  }),

  [fetchOrganizationProfiles.succeeded]: (state, action) => ({
    ...state,
    profileEntities: action.payload.profiles,
  }),
})

export { organizationReducer }
