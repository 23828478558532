import React from 'react'

import packageJson from '../../../package.json'
import { DEPLOYMENT_ENV } from '../../utils/env'

import { FooterContainer, FooterContent, CopyRightLabel } from './styles'

const Footer = () => (
  <FooterContainer>
    <FooterContent>
      <CopyRightLabel>
        © {new Date().getFullYear()} Downer Limited. All rights reserved.{' '}
        {DEPLOYMENT_ENV.toUpperCase()} v{packageJson.version}
      </CopyRightLabel>
    </FooterContent>
  </FooterContainer>
)

export { Footer }
