import { createActionNamespace } from '@soltalabs/react.redux-toolkit'

const { createActionCreator, createAsyncActionCreators } = createActionNamespace('jobs')

const fetchJobs = createAsyncActionCreators('FETCH_JOBS', [
  (sortBy, next) => ({ sortBy, next }),
  (sortBy, next, jobs = {}, ids = []) => ({ sortBy, next, jobs, ids }),
  (errorMessage) => ({ errorMessage }),
])

const fetchNextJobs = createAsyncActionCreators('FETCH_NEXT_JOBS', [
  (sortBy, next) => ({ sortBy, next }),
  (sortBy, next, jobs = {}, ids = []) => ({ sortBy, next, jobs, ids }),
  (errorMessage) => ({ errorMessage }),
])

const updateSingleJob = createAsyncActionCreators('UPDATE_SINGLE_JOB', [
  (jobUpdate) => ({ jobUpdate }),
  (job = {}) => ({ job }),
  (errorMessage) => ({ errorMessage }),
])

const updateMultipleJobs = createAsyncActionCreators('UPDATE_MULTIPLE_JOBS', [
  (jobUpdates) => ({ jobUpdates }),
  (jobs = {}) => ({ jobs }),
  (errorMessage) => ({ errorMessage }),
])

const reorderJobs = createActionCreator('REORDER_JOBS', (source, destination) => ({
  source,
  destination,
}))

const addJob = createAsyncActionCreators('ADD_JOB', [
  (jobValues, sortBy) => ({ jobValues, sortBy }),
  (job = {}) => ({ job }),
  (errorMessage) => ({ errorMessage }),
])

const setAddJobAddress = createActionCreator('SET_ADD_JOB_ADDRESS', (address) => ({
  address,
}))

const setAddJobLocation = createActionCreator('SET_ADD_JOB_LOCATION', (location) => ({
  location,
}))

const setSelectedJob = createActionCreator('SET_SELECTED_JOB', (id) => ({
  id,
}))

const setHoveredJob = createActionCreator('SET_HOVERED_JOB', (id) => ({
  id,
}))

const setDeletingJob = createActionCreator('SET_DELETING_JOB', (id) => ({
  id,
}))

const deleteJob = createAsyncActionCreators('DELETE_JOB', [
  (id) => ({ id }),
  (id) => ({ id }),
  (errorMessage) => ({ errorMessage }),
])

export {
  fetchJobs,
  fetchNextJobs,
  updateSingleJob,
  updateMultipleJobs,
  reorderJobs,
  addJob,
  setAddJobAddress,
  setAddJobLocation,
  setSelectedJob,
  setHoveredJob,
  setDeletingJob,
  deleteJob,
}
