import styled from 'styled-components'

import { COLORS } from '../../../constants/colors'

const MemberSuggestionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const IconNameContainer = styled.div`
  display: flex;
  align-items: center;
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 15px;
`

const MemberName = styled.div`
  font-size: 16px;
`

const MemberTitle = styled.div`
  font-size: 12px;
  color: #888888;
`

const MemberEmail = styled.div`
  justify-self: flex-end;
  color: ${COLORS.THEME.PRIMARY};
`

const AddMemberContainer = styled.div`
  display: flex;
  margin: 25px 0;
  align-items: center;

  .react-autosuggest__container {
    width: 500px;
  }

  .react-autosuggest__suggestions-container--open {
    width: 500px;
    border-top: none;
    border-radius: 4px;
    position: absolute;
    z-index: 2;
    background: white;
    overflow-y: scroll;
    box-shadow: 0 2px 6px 0 rgba(26, 25, 25, 0.2);
    margin-bottom: 10px;
  }

  .react-autosuggest__suggestions-list {
    padding: 0;
    margin: 0;
    max-height: 300px;
  }

  .react-autosuggest__suggestion {
    padding: 20px 15px;
    list-style: none;
    cursor: pointer;
  }

  .react-autosuggest__suggestion--highlighted {
    background: #f0f0f0;
  }

  .react-autosuggest__input {
    height: 32px;
    width: 460px;
    outline: none;
    border-radius: 4px;
    border: 1px solid rgba(26, 25, 25, 0.2);
    padding: 10px 20px;
    font-size: 20px;
    min-height: 32px;
    &::placeholder {
      opacity: 0.5;
    }

    &:focus {
      box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
    }
  }
`

export {
  MemberSuggestionContainer,
  IconNameContainer,
  NameContainer,
  MemberName,
  MemberTitle,
  MemberEmail,
  AddMemberContainer,
}
