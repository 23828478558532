import { Card, LinearProgress, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import { COLORS } from '../../../constants/colors'

const NameLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const JobContainer = styled.div`
  cursor: grab;
  padding: ${(props) => props.spacing / 2}px;
  max-width: 328px;
  overflow: hidden;
  box-sizing: border-box;
`

const JobContent = styled(Card)`
  && {
    border: 1px solid rgba(26, 25, 25, 0.2);
    border-radius: 4px;
    box-shadow: 0 2px 6px 0 rgba(26, 25, 25, 0.2);
    :hover {
      border: 1px solid rgba(55, 111, 139, 0.6);
    }
  }
`

const NameLabel = styled.div`
  margin: 12px 15px;
  color: rgba(26, 25, 25, 0.6);

  font-size: 18px;
  font-weight: 800;
  letter-spacing: -0.75px;
  line-height: 24px;
`

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin: 0 15px 5px;
  position: relative;
`

const InfoContainer = styled(SectionContainer)`
  height: 44px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const InfoLabel = styled.div`
  color: #1a1919;

  font-size: 12px;
  line-height: 18px;
`

const DayLabel = styled.div`
  color: #1a191988;
  width: 33%;

  text-align: ${(props) => props.align ?? 'center'};

  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.5px;
`

const FinishBy = styled.div`
  color: ${(props) => (props.highRisk ? '#DB5B5B' : '#1A1919')};

  font-size: 14px;
  line-height: 18px;
  font-weight: 800;
`

const ProductColorBar = styled.div`
  width: 8px;
  border-radius: 0 4px 4px 0;
  background-color: ${(props) => props.color};
  position: absolute;
  height: 100%;
  margin-left: -15px;
`

const ColorBar = styled.div`
  width: 6px;
  height: 12px;
  background: ${(props) => props.color};

  border-left: 1px solid rgba(26, 25, 25, 0.2);
  border-right: 1px solid rgba(26, 25, 25, 0.2);
  &:hover {
    cursor: pointer;
    height: 18px;
    margin-top: -10px;
    border-left: 1px solid rgba(55, 111, 139, 0.6);
    border-right: 1px solid rgba(55, 111, 139, 0.6);
  }
`

const ProductDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FinishByContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const AssessmentContainer = styled.div`
  display: flex;
  align-items: flex-end;
`

const StyledLinearProgress = withStyles({
  root: {
    height: '8px',
  },
  colorPrimary: {
    backgroundColor: 'rgba(26,25,25,0.2)',
  },
  barColorPrimary: {
    backgroundColor: `${COLORS.THEME.SECONDARY}`,
  },
})(LinearProgress)

const StyledTooltip = withStyles({
  tooltip: {
    padding: '4px',
    maxWidth: 256,
    fontFamily: 'Nunito Sans',
    fontSize: '12px',
    fontWeight: 700,
    backgroundColor: 'rgba(0, 0, 0, 0.92)',
    color: 'white',
    borderRadius: '5px',
  },
})(Tooltip)

export {
  NameLabelContainer,
  JobContainer,
  JobContent,
  NameLabel,
  SectionContainer,
  InfoLabel,
  ProductDetailsContainer,
  ColorBar,
  FinishByContainer,
  FinishBy,
  DayLabel,
  AssessmentContainer,
  ProductColorBar,
  StyledLinearProgress,
  InfoContainer,
  StyledTooltip,
}
