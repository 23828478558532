import { normalize } from 'normalizr'
import { call, put, takeLatest } from 'redux-saga/effects'

import { getProductsApiRequest } from '../../api/product'

import { GET_PRODUCTS, productActionCreators } from './actions'
import { productsResponseSchema } from './schemas'

function* getProductsWatcher() {
  yield takeLatest(GET_PRODUCTS.REQUESTED, getProductsSaga)
}

function* getProductsSaga({ payload }) {
  try {
    const productsEntitesResult = normalize(
      yield call(getProductsApiRequest, payload),
      productsResponseSchema
    )

    yield put(productActionCreators.getProducts.succeeded(productsEntitesResult))
  } catch (error) {
    yield put(productActionCreators.getProducts.failed(error.message))
  }
}

const productSagas = [getProductsWatcher()]

export { productSagas }
