import { toast } from 'react-toastify'
import { takeLatest, call, put } from 'redux-saga/effects'

import { CrewService } from '../../api/crew'

import {
  fetchCrews,
  createCrew,
  deleteCrew,
  watchCrew,
  unwatchCrew,
  addCrewMember,
  removeCrewMember,
  createCrewInvitation,
  deleteCrewInvitation,
} from './actions'

function* fetchCrewsSaga() {
  yield takeLatest(fetchCrews.request, function*() {
    try {
      const { crews, members } = yield call(CrewService.list)

      yield put(fetchCrews.succeeded(crews, members))
    } catch (error) {
      yield put(fetchCrews.failed(error.message))
    }
  })
}

function* createCrewSaga() {
  yield takeLatest(createCrew.request, function*(action) {
    const { name } = action.payload

    try {
      const crew = yield call(CrewService.create, name)

      yield put(createCrew.succeeded(crew))
      yield toast.success('New Crew Created')
    } catch (error) {
      yield put(createCrew.failed(error.message))
      toast.error(`Create Crew Failed: ${error.message}`)
    }
  })
}

function* deleteCrewSaga() {
  yield takeLatest(deleteCrew.request, function*(action) {
    const { id } = action.payload

    try {
      yield call(CrewService.delete, id)
      yield put(deleteCrew.succeeded(id))
    } catch (error) {
      yield put(deleteCrew.failed(id, error.message))
    }
  })
}

function* watchCrewSaga() {
  yield takeLatest(watchCrew.request, function*(action) {
    const { id } = action.payload

    try {
      yield call(CrewService.watch, id)
      yield put(watchCrew.succeeded(id))
    } catch (error) {
      yield put(watchCrew.failed(error.message))
    }
  })
}

function* unwatchCrewSaga() {
  yield takeLatest(unwatchCrew.request, function*(action) {
    const { id } = action.payload

    try {
      yield call(CrewService.unwatch, id)
      yield put(unwatchCrew.succeeded(id))
    } catch (error) {
      yield put(unwatchCrew.failed(error.message))
    }
  })
}

function* addCrewMemberSaga() {
  yield takeLatest(addCrewMember.request, function*(action) {
    const { crewId, memberId } = action.payload

    try {
      const { crew, member } = yield call(CrewService.addCrewMember, crewId, memberId)
      yield put(addCrewMember.succeeded(crew, member))
    } catch (error) {
      yield put(addCrewMember.failed(error.message))
    }
  })
}

function* removeCrewMemberSaga() {
  yield takeLatest(removeCrewMember.request, function*(action) {
    const { crewId, memberId } = action.payload

    try {
      const crew = yield call(CrewService.removeCrewMember, crewId, memberId)

      yield put(removeCrewMember.succeeded(crew, memberId))
    } catch (error) {
      yield put(removeCrewMember.failed(error.message))
    }
  })
}

function* createCrewInvitationSaga() {
  yield takeLatest(createCrewInvitation.request, function*(action) {
    const { crewId, email } = action.payload

    try {
      const invitation = yield call(CrewService.createCrewInvitation, crewId, email)

      yield put(createCrewInvitation.succeeded(crewId, invitation))
      toast.success('Invitation sent')
    } catch (error) {
      yield put(createCrewInvitation.failed(error.message))
      toast.error(`Invite Failed: ${error.message}`)
    }
  })
}

function* deleteCrewInvitationSaga() {
  yield takeLatest(deleteCrewInvitation.request, function*(action) {
    const { crewId, invitationId } = action.payload

    try {
      yield call(CrewService.deleteCrewInvitation, invitationId)
      yield put(deleteCrewInvitation.succeeded(crewId, invitationId))
    } catch (error) {
      yield put(deleteCrewInvitation.failed(error.message))
      toast.error(`Delete invitation Failed: ${error.message}`)
    }
  })
}

const crewSagas = [
  fetchCrewsSaga(),
  createCrewSaga(),
  deleteCrewSaga(),
  watchCrewSaga(),
  unwatchCrewSaga(),
  addCrewMemberSaga(),
  removeCrewMemberSaga(),
  createCrewInvitationSaga(),
  deleteCrewInvitationSaga(),
]

export { crewSagas }
