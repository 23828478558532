const COLORS = {
  THEME: {
    PRIMARY: '#5CBBDB',
    SECONDARY: '#C9177E',
  },
  MAIN: {
    BACKGROUND: '#FFFFFF',
  },
  FORM: {
    BACKGROUND: '#363539',
  },
  COMMON: {
    HOVER: 'rgba(55, 111, 139, 0.6)',
  },
  ASSESSMENT: {
    RED: '#DB5B5B',
    YELLOW: '#F4C11D',
    GREEN: '#8CC140',
  },
  PIN: {
    RED: '#D40000',
    YELLOW: '#E29400',
    GREEN: '#72C100',
  },
}

export { COLORS }
