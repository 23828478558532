import React from 'react'
import { ShepherdTour } from 'react-shepherd'

const tourOptions = {
  useModalOverlay: true,
}

const steps = [
  {
    id: 'intro',
    title: 'Zeus Tutorial',
    text: ['Do you wish to begin the Zeus tutorial?'],
    buttons: [
      { classes: 'shepherd-button-secondary', text: 'Not Now', type: 'cancel' },
      { classes: 'shepherd-button-primary', text: 'Start', type: 'next' },
    ],
  },

  {
    id: 'downloading-the-template',
    attachTo: {
      element: '.link-download-template',
      on: 'bottom',
    },
    title: 'Importing jobs',
    text: ['Download the template to begin importing jobs into Zeus.'],
    buttons: [{ classes: 'shepherd-button-primary', text: 'Next', type: 'next' }],
  },

  {
    id: 'import-jobs',
    attachTo: {
      element: '.button-import-jobs',
      on: 'bottom',
    },
    title: 'Importing jobs',
    text: ['Fill out the template, import it back into Zeus by clicking this button.'],
    buttons: [
      { classes: 'shepherd-button-secondary', text: 'Back', type: 'back' },
      { classes: 'shepherd-button-primary', text: 'Next', type: 'next' },
    ],
  },

  {
    id: 'job-list',
    attachTo: {
      element: '.container-job-list',
      on: 'right',
    },
    title: 'Job list',
    text: ['Jobs imported into Zeus will appear in here.'],
    buttons: [
      { classes: 'shepherd-button-secondary', text: 'Back', type: 'back' },
      { classes: 'shepherd-button-primary', text: 'Next', type: 'next' },
    ],
  },
  {
    id: 'sorting-jobs',
    attachTo: {
      element: '.dropdown-sort-by',
      on: 'bottom',
    },
    title: 'Sorting jobs',
    text: [
      'Sort jobs by priority, finish by date, or assessments by clicking this dropdown.',
    ],
    buttons: [
      { classes: 'shepherd-button-secondary', text: 'Back', type: 'back' },
      { classes: 'shepherd-button-primary', text: 'Next', type: 'next' },
    ],
  },
  {
    id: 'map-and-grid-view',
    attachTo: {
      element: '.toggle-group-map',
      on: 'left',
    },
    advanceOn: {
      selector: '.toggle-group-map #grid-view',
      event: 'click',
    },
    title: 'Map and grid view',
    text: ['Swap between map and grid view using here. Try it out now!'],
    buttons: [{ classes: 'shepherd-button-secondary', text: 'Back', type: 'back' }],
  },
  {
    id: 'drag-and-drop',
    attachTo: {
      element: '.container-job-list',
      on: 'top',
    },
    title: 'Re-ordering jobs',
    text: ['To re-order jobs in grid view, simply drag-and-drop them.'],
    buttons: [
      { classes: 'shepherd-button-secondary', text: 'Back', type: 'back' },
      { classes: 'shepherd-button-primary', text: 'Next', type: 'next' },
    ],
  },
  {
    id: 'back-to-map-view',
    attachTo: {
      element: '.toggle-group-map',
      on: 'left',
    },
    advanceOn: {
      selector: '.toggle-group-map #map-view',
      event: 'click',
    },
    text: ["Lets go back to the map view to see the rest of Zeus's features."],
    buttons: [{ classes: 'shepherd-button-secondary', text: 'Back', type: 'back' }],
  },
  {
    id: 'add-job',
    attachTo: {
      element: '.container-map',
    },
    title: 'Add job',
    text: ['Add a job by double-clicking on a location.'],
    buttons: [
      { classes: 'shepherd-button-secondary', text: 'Back', type: 'back' },
      { classes: 'shepherd-button-primary', text: 'Next', type: 'next' },
    ],
  },
  {
    id: 'search-location',
    attachTo: {
      element: '.input-geocoder',
      on: 'bottom',
    },
    title: 'Add job',
    text: ['Alternatively, you can add a job by searching for a location.'],
    buttons: [
      { classes: 'shepherd-button-secondary', text: 'Back', type: 'back' },
      { classes: 'shepherd-button-primary', text: 'Next', type: 'next' },
    ],
  },
  {
    id: 'time-series',
    attachTo: {
      element: '.slider-forecasts',
      on: 'top',
    },
    title: 'Time series',
    text: [
      "Zeus overlays the map with precipitation data for a given hourly block. To change the forecast's time, move the slider.",
    ],
    buttons: [
      { classes: 'shepherd-button-secondary', text: 'Back', type: 'back' },
      { classes: 'shepherd-button-primary', text: 'Next', type: 'next' },
    ],
  },
  {
    id: 'done',
    title: 'Done!',
    text: ["You're now ready to use Zeus!"],
    buttons: [{ classes: 'shepherd-button-primary', text: 'Done', type: 'next' }],
  },
]

function withTutorial(Component) {
  return (props) => (
    <ShepherdTour steps={steps} tourOptions={tourOptions}>
      <Component {...props} />
    </ShepherdTour>
  )
}

export { withTutorial }
