import fetch from 'isomorphic-fetch'
import { schema, normalize } from 'normalizr'

import { FORECAST_POLYGONS_ENDPOINT } from '../../utils/env'

const forecastSchema = new schema.Entity('forecasts', [], {
  idAttribute: 'forecastStartTime',
})

const ForecastService = {
  async list() {
    const resp = await fetch(
      `${FORECAST_POLYGONS_ENDPOINT}/precipitation_polygons.json`
    )

    const result = await resp.json()
    const normalized = normalize(result, [forecastSchema])
    const { forecasts } = normalized.entities

    return { forecasts }
  },
}

export { ForecastService }
