import {
  mergeDeepRight,
  concat,
  omit,
  without,
  insert,
  filter,
  prop,
} from '@soltalabs/ramda-extra'
import { createReducer } from '@soltalabs/react.redux-toolkit'

import { JOBS } from '../../constants/jobs'
import { swapByIndex } from '../job'

import {
  fetchScheduledJobs,
  fetchUnassignedJobs,
  fetchNextUnassignedJobs,
  updateSingleUnassignedJob,
  updateMultipleUnassignedJobs,
  scheduleJob,
  unscheduleJob,
  reorderUnassignedJobs,
  deleteUnassignedJob,
} from './actions'

const INITIAL_STATE = Object.freeze({
  scheduledJobEntities: {},
  unassignedJobsSortBy: JOBS.SORTBY.PRIORITY.value,
  unassignedJobEntities: {},
  unassignedJobIds: [],
})

const schedulerReducer = createReducer(INITIAL_STATE, {
  [fetchScheduledJobs.succeeded]: (state, action) => ({
    ...state,
    scheduledJobEntities: mergeDeepRight(
      state.scheduledJobEntities,
      action.payload.jobs
    ),
  }),
  [fetchUnassignedJobs.succeeded]: (state, action) => ({
    ...state,
    unassignedJobsSortBy: action.payload.sortBy,
    unassignedJobsNext: action.payload.next,
    unassignedJobEntities: action.payload.jobs,
    unassignedJobIds: action.payload.ids,
  }),
  [fetchNextUnassignedJobs.succeeded]: (state, action) => ({
    ...state,
    unassignedJobsSortBy: action.payload.sortBy,
    unassignedJobsNext: action.payload.next,
    unassignedJobEntities: mergeDeepRight(
      state.unassignedJobEntities,
      action.payload.jobs
    ),
    unassignedJobIds: concat(state.unassignedJobIds, action.payload.ids),
  }),
  [updateSingleUnassignedJob.succeeded]: (state, action) => ({
    ...state,
    unassignedJobEntities: mergeDeepRight(state.unassignedJobEntities, {
      [action.payload.job.id]: action.payload.job,
    }),
  }),
  [updateMultipleUnassignedJobs.succeeded]: (state, action) => ({
    ...state,
    unassignedJobEntities: mergeDeepRight(
      state.unassignedJobEntities,
      action.payload.jobs
    ),
  }),
  [scheduleJob.request]: (state, action) => ({
    ...state,
    scheduledJobEntities: mergeDeepRight(state.scheduledJobEntities, {
      [action.payload.jobId]: {
        ...prop(action.payload.jobId, state.scheduledJobEntities),
        ...prop(action.payload.jobId, state.unassignedJobEntities),
        crewId: action.payload.crewId,
        scheduledAt: action.payload.scheduledAt,
      },
    }),
    unassignedJobEntities: omit([action.payload.jobId], state.unassignedJobEntities),
    unassignedJobIds: filter(
      (id) => id !== action.payload.jobId,
      state.unassignedJobIds
    ),
  }),
  [scheduleJob.succeeded]: (state, action) => ({
    ...state,
    scheduledJobEntities: mergeDeepRight(state.scheduledJobEntities, {
      [action.payload.job.id]: action.payload.job,
    }),
  }),
  [unscheduleJob.request]: (state, action) => ({
    ...state,
    scheduledJobEntities: omit([action.payload.id], state.scheduledJobEntities),
    unassignedJobEntities: mergeDeepRight(state.unassignedJobEntities, {
      [action.payload.id]: {
        ...state.scheduledJobEntities[action.payload.id],
        crewId: null,
        scheduledAt: null,
        crew: null,
      },
    }),
    unassignedJobIds: insert(
      action.payload.index,
      action.payload.id,
      state.unassignedJobIds
    ),
  }),
  [unscheduleJob.succeeded]: (state, action) => ({
    ...state,
    unassignedJobEntities: mergeDeepRight(state.unassignedJobEntities, {
      [action.payload.job.id]: action.payload.job,
    }),
  }),
  [reorderUnassignedJobs]: (state, action) => ({
    ...state,
    unassignedJobIds: swapByIndex(
      action.payload.sourceIndex,
      action.payload.destinationIndex,
      state.unassignedJobIds
    ),
  }),
  [deleteUnassignedJob.succeeded]: (state, action) => ({
    ...state,
    unassignedJobEntities: omit([action.payload.id], state.unassignedJobEntities),
    unassignedJobIds: without([action.payload.id], state.unassignedJobIds),
  }),
})

export { schedulerReducer }
