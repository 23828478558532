import { path } from '@soltalabs/ramda-extra'

import { createAsyncActionTypes, createAsyncActionCreators } from '../../utils/redux'

const GET_PRODUCTS = createAsyncActionTypes('GET_PRODUCTS')

const getProductsActionCreators = createAsyncActionCreators(GET_PRODUCTS, [
  () => ({}),
  (response) => ({ entities: path(['entities', 'products'], response) }),
  (errorMessage) => ({ errorMessage }),
])

const productActionCreators = {
  ...getProductsActionCreators,
}

export { GET_PRODUCTS, productActionCreators }
