import styled from 'styled-components'
import { Card, LinearProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { COLORS } from '../../../constants/colors'

const NameLabelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const JobContainer = styled.div`
  ${(props) =>
    props.card &&
    `
    cursor: ${props.sortDisabled ? 'default' : 'grab'};
    padding: ${props.spacing / 2}px;
    width: ${100 / props.cols}%;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
  `}
`

const JobContent = styled(Card)`
  && {
    ${(props) =>
      props.card &&
      `
      border: ${
        props.selected
          ? '1px solid rgba(26, 25, 25, 0.4)'
          : '1px solid rgba(26, 25, 25, 0.2)'
      };
      border-radius: 4px;
      box-shadow: ${props.selected ? '0 2px 6px 0 rgba(26,25,25,0.2)' : 'none'};
      :hover {
        border: 1px solid rgba(55, 111, 139, 0.6);
      }
    `}
  }
`

const DeleteButtonsContainer = styled.div`
  margin: 10px 5px;
  position: absolute;
  right: 0;
  top: 0;
  margin: 5px;
  display: flex;
  flex-direction: column;
`

const NameLabel = styled.div`
  margin: 15px 15px 8px 15px;
  color: rgba(26, 25, 25, 0.6);

  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.25px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const PriorityNumber = styled.div`
  font-size: 10px;
  color: rgba(26, 25, 25, 0.7);
  background: ${COLORS.THEME.SECONDARY}33;
  border-radius: 24px;
  margin-right: 6px;
  min-width: 14px;
  height: 17px;
  text-align: center;
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const SectionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  margin: 0 15px 10px;
  position: relative;
`

const InfoContainer = styled(SectionContainer)`
  height: 44px;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const InfoLabel = styled.div`
  color: #1a1919;

  font-size: 16px;
  line-height: 22px;
`

const DayLabel = styled.div`
  color: #1a191988;
  width: 33%;

  text-align: ${(props) => props.align ?? 'center'};

  font-size: 10px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.5px;
`

const FinishBy = styled.div`
  color: ${(props) => (props.highRisk ? '#DB5B5B' : '#1A1919')};

  font-size: 18px;
  line-height: 22px;
  font-weight: 800;
`

const ProductColorBar = styled.div`
  width: 8px;
  border-radius: 0 4px 4px 0;
  background-color: ${(props) => props.color};
  position: absolute;
  height: 100%;
  margin-left: -15px;
`

const ProductDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const FinishByContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const AssessmentContainer = styled.div`
  display: flex;
  align-items: flex-end;
`

const StyledLinearProgress = withStyles({
  root: {
    height: '8px',
  },
  colorPrimary: {
    backgroundColor: 'rgba(26,25,25,0.2)',
  },
  barColorPrimary: {
    backgroundColor: `${COLORS.THEME.SECONDARY}`,
  },
})(LinearProgress)

const ScheduleInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: -6px;
`

const CrewNameIndicator = styled.div`
  background-color: ${COLORS.THEME.PRIMARY}88;
  font-size: 10px;
  padding: 2px 6px;
  border-radius: 0 0 4px 0;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 800;
`

export {
  NameLabelContainer,
  JobContainer,
  JobContent,
  DeleteButtonsContainer,
  NameLabel,
  PriorityNumber,
  SectionContainer,
  InfoContainer,
  InfoLabel,
  DayLabel,
  FinishBy,
  ProductColorBar,
  ProductDetailsContainer,
  FinishByContainer,
  AssessmentContainer,
  StyledLinearProgress,
  ScheduleInfo,
  CrewNameIndicator,
}
