import AccountCircleIcon from '@material-ui/icons/AccountCircle'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { deepMemo } from '../../../utils/react'

import {
  Container,
  MemberInfoContainer,
  MemberNameContainer,
  MemberName,
  MemberTitle,
  MemberEmail,
  Divider,
  RemoveMemberButton,
} from './styles'

function View({
  member,
  showRemoveButton,
  onMouseEnter,
  onMouseLeave,
  onRemoveMemberButtonClick,
}) {
  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <MemberInfoContainer>
        <AccountCircleIcon fontSize="large" style={{ color: COLORS.THEME.SECONDARY }} />
        <MemberNameContainer>
          <MemberName>
            {member.firstName} {member.lastName}
          </MemberName>
          <MemberTitle>{member.title}</MemberTitle>
        </MemberNameContainer>
        <Divider />
        <MemberEmail href={`mailto:${member.email}`}>{member.email}</MemberEmail>
      </MemberInfoContainer>
      {showRemoveButton && (
        <RemoveMemberButton size="small" onClick={onRemoveMemberButtonClick}>
          <RemoveCircleIcon color="secondary" />
        </RemoveMemberButton>
      )}
    </Container>
  )
}

const CrewMemberRowView = deepMemo(View)

export { CrewMemberRowView }
