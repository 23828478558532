import {
  ForgotPassword,
  SignIn,
  ConfirmSignIn,
  ConfirmSignUp,
  Loading,
  withAuthenticator,
} from 'aws-amplify-react'
import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import { SignUp } from '../components/auth/SignUp'
import { CrewsPage } from '../components/crews/CrewsPage'
import { SchedulerPage } from '../components/scheduler/SchedulerPage'
import { COLORS } from '../constants/colors'
import { getIsManager } from '../modules/organization'
import customLoadable from '../utils/customLoadable'

const theme = {
  a: { color: COLORS.THEME.PRIMARY },
  button: { backgroundColor: COLORS.THEME.SECONDARY, borderRadius: '4px' },
  container: { fontFamily: 'Nunito Sans', fontSize: '16px' },
  input: { fontFamily: 'Nunito Sans', fontSize: '16px' },
  inputLabel: { fontFamily: 'Nunito Sans', fontSize: '16px' },
  sectionHeader: { fontSize: '20px' },
}

const Main = customLoadable({
  loader: () => import('./main.page'),
})

const mapStateToProps = (state) => ({
  isManager: getIsManager(state),
})

function AppRoutes({ isManager }) {
  return (
    <Switch>
      {isManager && <Route path="/scheduler" component={SchedulerPage} />}
      {isManager && <Route path="/crews" component={CrewsPage} />}
      <Route exact={isManager} path="/" component={Main} />
    </Switch>
  )
}

const ConnectedAppRoutes = connect(mapStateToProps)(AppRoutes)

const AuthenticatorAppRoutes = withAuthenticator(ConnectedAppRoutes, {
  includeGreetings: false,
  authenticatorComponents: [
    <SignIn />,
    <ConfirmSignIn />,
    <SignUp override={'SignUp'} />,
    <ConfirmSignUp />,
    <ForgotPassword />,
    <Loading />,
  ],
  theme,
})

export { AuthenticatorAppRoutes }
