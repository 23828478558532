import { CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styled from 'styled-components'

import { COLORS } from '../../constants/colors'

const Container = styled.div`
  width: 100%;
`

const EmptyListPrompt = styled.div`
  margin: 20px auto;
  font-size: 24px;
  color: #a1a1a1;
  position: absolute;
  text-align: center;
  width: 100%;
`

const TitleLabel = styled.h1`
  font-weight: 800;
  color: rgba(26, 25, 25, 0.6);
  font-size: 34px;
  font-weight: 800;
  letter-spacing: -1.5px;
  line-height: 36px;
`

const StyledCircularProgress = withStyles({
  root: {
    margin: '20px auto',
    color: `${COLORS.THEME.SECONDARY}`,
  },
})(CircularProgress)

export { Container, TitleLabel, EmptyListPrompt, StyledCircularProgress }
