import styled from 'styled-components'
import { Container, Divider, Fab, Button } from '@material-ui/core'
import { COLORS } from '../../constants/colors'

const HeaderContainer = styled.div`
  top: 0;
  height: 82px;
  background-color: white;
  box-shadow: none;
  width: 100%;
`

const HeaderContent = styled(Container)`
  && {
    border-bottom: 1px solid rgba(26, 25, 25, 0.2);
    box-shadow: 0 6px 6px -6px rgba(26, 25, 25, 0.2);
    margin: 0 auto;
    display: flex;
    align-items: center;
    height: 100%;
    max-width: 1440px;
    justify-content: space-between;

    @media (min-width: 600px) {
      padding-left: 104px;
      padding-right: 104px;
    }
    @media (min-width: 960px) {
      padding-left: 112px;
      padding-right: 112px;
    }
  }
`

const StyledDivider = styled(Divider)`
  && {
    height: 40px;
  }
`

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`

const WelcomeLabel = styled.div`
  color: #1a1919;

  font-size: 16px;
  line-height: 20px;
  text-align: right;
  margin: 0 15px;
`

const StyledFab = styled(Fab)`
  && {
    background-color: ${COLORS.THEME.PRIMARY};
    height: 36px;
    width: 36px;
    margin: 0 5px;
    box-shadow: none;
  }
`

const StyledButton = styled(Button)`
  && {
    border: 1px solid #376f8b;
    color: #376f8b;

    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 7px 15px;
    margin: 0 5px;

    :hover {
      background: white;
      border: 1px solid rgba(55, 111, 139, 0.4);
    }
  }
`

const TabsContainer = styled.div`
  display: flex;
  width: 100%;
`

export {
  HeaderContainer,
  HeaderContent,
  StyledDivider,
  SectionContainer,
  WelcomeLabel,
  StyledFab,
  StyledButton,
  TabsContainer,
}
