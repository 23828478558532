import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { JOBS } from '../../../constants/jobs'
import { fetchUnassignedJobs } from '../../../modules/scheduler'

import { UnassignedJobsSectionView } from './view'

function UnassignedJobsSection() {
  const dispatch = useDispatch()

  const [sortBy, setSortBy] = useState(JOBS.SORTBY.PRIORITY.value)

  function onSelectSortBy(event) {
    setSortBy(event.target.value)
    dispatch(fetchUnassignedJobs.request(event.target.value))
  }

  return <UnassignedJobsSectionView sortBy={sortBy} onSelectSortBy={onSelectSortBy} />
}

export { UnassignedJobsSection }
