import { handleActions } from 'redux-actions'

import { GET_PRODUCTS } from './actions'

const DEFAULT_STATE = []

const productsReducer = handleActions(
  {
    [GET_PRODUCTS.SUCCEEDED]: (state, { payload }) => payload,
  },
  DEFAULT_STATE
)

export { productsReducer }
