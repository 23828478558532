import { isNil } from '@soltalabs/ramda-extra'
import { Hub, Auth } from 'aws-amplify'
import { eventChannel } from 'redux-saga'
import { takeLatest, take, race, call, put } from 'redux-saga/effects'

import { signOutApiRequest } from '../../api/auth'
import { recordAuditEvent } from '../audit'

import { initializeCurrentUser, currentUserChanged, signOut } from './actions'

function* initializeCurrentUserSaga() {
  try {
    const currentUser = yield call([Auth, Auth.currentAuthenticatedUser])
    yield put(initializeCurrentUser.succeeded(currentUser))

    if (!isNil(currentUser)) {
      yield put(recordAuditEvent.request({ resource: 'session', action: 'create' }))
    }
  } catch (error) {
    yield put(initializeCurrentUser.succeeded(undefined))
  }
}

function* currentUserSaga() {
  const channel = createAuthEventChannel()

  while (true) {
    const action = yield take(channel)
    yield put(action)
  }
}

function createAuthEventChannel() {
  return eventChannel((emit) => {
    const onAuthEvent = (capsule) => {
      const { event } = capsule.payload

      if (event === 'signIn') {
        Auth.currentAuthenticatedUser().then((currentUser) => {
          emit(currentUserChanged(currentUser))
          emit(recordAuditEvent.request({ resource: 'session', action: 'create' }))
        })
      }

      if (event === 'signOut') {
        emit(currentUserChanged(undefined))
        emit(recordAuditEvent.request({ resource: 'session', action: 'delete' }))
      }
    }

    Hub.listen('auth', onAuthEvent)
    return () => Hub.remove('auth', onAuthEvent)
  })
}

function* signOutSaga() {
  yield takeLatest(signOut.request, function*() {
    try {
      yield put(recordAuditEvent.request({ resource: 'session', action: 'delete' }))
      yield race([take(recordAuditEvent.succeeded), take(recordAuditEvent.failed)])

      yield call(signOutApiRequest)
      yield put(signOut.succeeded())
    } catch (error) {
      yield put(signOut.failed(error.message))
    }
  })
}

const authSagas = [initializeCurrentUserSaga(), currentUserSaga(), signOutSaga()]

export { authSagas }
