import { I18n } from 'aws-amplify'
import { ConnectedRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import React from 'react'
import { Provider } from 'react-redux'
import { Switch, Route } from 'react-router-dom'

import { configureAmplify } from '../api/amplify'
import { AccountActivationErrorPage } from '../components/auth/AccountActivationErrorPage'
import { AccountActivationPage } from '../components/auth/AccountActivationPage'
import { Toaster } from '../components/common/Toaster'
import { Footer } from '../components/Footer'
import { Header } from '../components/Header'
import { AcceptInvitationErrorPage } from '../components/invitation/AcceptInvitationErrorPage'
import { AcceptInvitationPage } from '../components/invitation/AcceptInvitationPage'
import { withTutorial } from '../components/tutorial/withTutorial'
import { AuthenticatorAppRoutes } from '../routes'

import { configureStore } from './root.store'

configureAmplify()

const history = createBrowserHistory()
const store = configureStore(history)

const authScreenLabels = {
  en: {
    Username: 'Email',
    'Enter your username': 'Enter your email',
  },
}

I18n.setLanguage('en')
I18n.putVocabularies(authScreenLabels)

const Root = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Header />
      <Toaster />

      <Switch>
        <Route exact path="/account-activated" component={AccountActivationPage} />
        <Route
          exact
          path="/account-activation-error"
          component={AccountActivationErrorPage}
        />
        <Route exact path="/accept-invitation" component={AcceptInvitationPage} />
        <Route
          exact
          path="/accept-invitation-error"
          component={AcceptInvitationErrorPage}
        />

        <Route path="/" component={AuthenticatorAppRoutes} />
      </Switch>
      <Footer />
    </ConnectedRouter>
  </Provider>
)

const TutorialRoot = withTutorial(Root)

export { TutorialRoot as Root }
