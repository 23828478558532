import styled from 'styled-components'

const Container = styled.div`
  margin-right: 10px;
  background: #f4f4f4;
  padding: 35px 0 10px;
  position: relative;
  cursor: grab;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`

const ListContainer = styled.div`
  min-width: 328px;
  min-height: 185px;
  width: 100%;
  height: 100%;
  background: ${(props) => (props.isDraggingOver ? '#e4e4e4' : '#f4f4f4')};
`

const Day = styled.div`
  margin: 10px 8px;
  font-size: 14px;
  font-weight: 800;
  position: absolute;
  top: 0;
`

export { Container, ListContainer, Day }
