import { SignUp as AmplifySignUp } from 'aws-amplify-react'
import React from 'react'

import { signUpApiRequest } from '../../../api/auth'

import { SignUpView } from './view'

class SignUp extends AmplifySignUp {
  constructor(props) {
    super(props)
    this.state = { firstName: '', lastName: '', email: '', password: '' }
  }

  onSignUp = (event) => {
    event.preventDefault()

    this.changeState('loading')
    signUpApiRequest({ values: this.state })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          throw Error(response.message)
        }
        this.changeState('signIn')
        this.error('Sign up success!')
      })
      .catch((error) => {
        this.changeState('signUp')
        this.error(error)
      })
  }

  onValueChange = (field) => (event) => {
    this.setState({ [field]: event.target.value })
  }

  onBackToSignIn = () => {
    this.changeState('signIn')
  }

  showComponent() {
    return (
      <SignUpView
        {...this.state}
        onSignUp={this.onSignUp}
        onValueChange={this.onValueChange}
        onBackToSignIn={this.onBackToSignIn}
      />
    )
  }
}

export { SignUp }
