import { takeLatest, call, put } from 'redux-saga/effects'
import { recordAuditEvent } from './actions'
import { AuditService } from '../../api/audit'

function* recordAuditEventSaga() {
  yield takeLatest(recordAuditEvent.request, function*(action) {
    const { event } = action.payload

    try {
      yield call(AuditService.recordEvent, event)
      yield put(recordAuditEvent.succeeded())
    } catch (error) {
      yield put(recordAuditEvent.failed(error.message))
    }
  })
}

const auditSagas = [recordAuditEventSaga()]

export { auditSagas }
