import { values, filter, contains, prop, isNil, includes } from '@soltalabs/ramda-extra'
import {
  createSelector,
  composeSelectors,
  createIsLoadingSelector,
} from '@soltalabs/react.redux-toolkit'
import CONSTANTS from '@soltalabs/roadscience.constants'

import { getCrewMemberIds } from '../crews'

import { fetchUserInfo, fetchOrganizationProfiles } from './actions'

const { ORG_FOREMAN } = CONSTANTS.ROLES

const getModuleState = createSelector('organization')

const getUserInfo = composeSelectors(getModuleState, createSelector('userInfo'))

const getUserRoles = composeSelectors(getUserInfo, (userInfo) => {
  const organizations = prop('organizations', userInfo)
  if (isNil(organizations)) {
    return []
  }

  return organizations.map((org) => org.role)
})

const getIsManager = composeSelectors(
  getUserRoles,
  (userRoles) => !includes(ORG_FOREMAN, userRoles)
)

const getProfileEntities = composeSelectors(
  getModuleState,
  createSelector('profileEntities')
)

const getProfiles = composeSelectors(getProfileEntities, (entities) => values(entities))

const getOrganizationProfilesNotInCrew = composeSelectors(
  getCrewMemberIds,
  getProfiles,
  (memberIds, profiles) =>
    filter((profile) => !contains(profile.id, memberIds), profiles)
)

const getIsFetchUserInfoLoading = createIsLoadingSelector([fetchUserInfo])

const getIsFetchOrganizationProfilesLoading = createIsLoadingSelector([
  fetchOrganizationProfiles,
])

export {
  getUserInfo,
  getIsManager,
  getOrganizationProfilesNotInCrew,
  getIsFetchUserInfoLoading,
  getIsFetchOrganizationProfilesLoading,
}
