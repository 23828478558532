import { isNil } from '@soltalabs/ramda-extra'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
  getUnassignedJobs,
  getUnassignedJobsSortBy,
  getUnassignedJobsNextPageId,
  getIsFetchUnassignedJobsLoading,
  getIsFetchNextUnassignedJobsLoading,
  fetchNextUnassignedJobs,
} from '../../../modules/scheduler'

import { UnassignedJobListView } from './view'

function UnassignedJobList() {
  const dispatch = useDispatch()

  const unassignedJobs = useSelector(getUnassignedJobs)
  const unassignedJobsSortBy = useSelector(getUnassignedJobsSortBy)
  const unassignedJobsNextPageId = useSelector(getUnassignedJobsNextPageId)
  const isFetchUnassignedJobsLoading = useSelector(getIsFetchUnassignedJobsLoading)
  const isFetchNextUnassignedJobsLoading = useSelector(
    getIsFetchNextUnassignedJobsLoading
  )

  const handleScroll = (e) => {
    const { scrollHeight, scrollTop, clientHeight } = e.target
    const isBottom = scrollHeight - scrollTop === clientHeight

    const shouldFetchNextJobs =
      isBottom &&
      !isNil(unassignedJobsNextPageId) &&
      !isFetchNextUnassignedJobsLoading &&
      !isFetchUnassignedJobsLoading

    if (shouldFetchNextJobs) {
      dispatch(
        fetchNextUnassignedJobs.request(unassignedJobsSortBy, unassignedJobsNextPageId)
      )
    }
  }

  return (
    <UnassignedJobListView
      jobList={unassignedJobs}
      isFetchJobsLoading={isFetchUnassignedJobsLoading}
      isFetchNextJobsLoading={isFetchNextUnassignedJobsLoading}
      onScroll={handleScroll}
    />
  )
}

export { UnassignedJobList }
