import { createActionNamespace } from '@soltalabs/react.redux-toolkit'

const { createActionCreator, createAsyncActionCreators } = createActionNamespace(
  'support'
)

const submitSupportTicket = createAsyncActionCreators('SUBMIT_SUPPORT_TICKET', [
  (message) => ({ message }),
  undefined,
  (errorMessage) => ({ errorMessage }),
])

const showSupportModal = createActionCreator('SHOW_SUPPORT_MODAL')
const hideSupportModal = createActionCreator('HIDE_SUPPORT_MODAL')

export { submitSupportTicket, showSupportModal, hideSupportModal }
