import { keys, head } from '@soltalabs/ramda-extra'
import { createReducer } from '@soltalabs/react.redux-toolkit'

import { fetchForecasts, updateSelectedForecastTime } from './actions'

const INITIAL_STATE = Object.freeze({
  entities: {},
  selectedTime: undefined,
})

const forecastsReducer = createReducer(INITIAL_STATE, {
  [fetchForecasts.succeeded]: (state, action) => ({
    ...state,
    entities: action.payload.forecasts,
    selectedTime: head(keys(action.payload.forecasts)),
  }),

  [updateSelectedForecastTime]: (state, action) => ({
    ...state,
    selectedTime: action.payload.selectedTime,
  }),
})

export { forecastsReducer }
