import { connect } from 'react-redux'
import { getIsLoggedIn, signOut } from '../../modules/auth'
import { fetchUserInfo, getUserInfo } from '../../modules/organization'
import { productActionCreators } from '../../modules/product'

const mapStateToProps = (state) => ({
  isLoggedIn: getIsLoggedIn(state),
  userInfo: getUserInfo(state),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchSignOut: () => dispatch(signOut.request()),
  dispatchFetchUserInfo: () => dispatch(fetchUserInfo.request()),
  dispatchGetProducts: () => dispatch(productActionCreators.getProducts.requested()),
})

const redux = (Component) => connect(mapStateToProps, mapDispatchToProps)(Component)

export { redux }
