import { Container, Button } from '@material-ui/core'
import styled from 'styled-components'

import { COLORS } from '../../../constants/colors'

const PageContainer = styled(Container)`
  && {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    padding-bottom: 30px;
  }
`

const CrewPanelsContainer = styled.div`
  height: 70vh;
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding: 5px;
  display: flex;
  flex-direction: column;
  algin-items: center;
  position: relative;
`

const AddCrewButtonsContainer = styled.div`
  padding: 0 3px 20px;
  display: flex;
  justify-content: flex-end;
`

const CancelButton = styled(Button)`
  && {
    color: ${COLORS.THEME.SECONDARY};
  }
`

const SubmitButton = styled(Button)`
  && {
    background: ${COLORS.THEME.PRIMARY};

    &:hover {
      background: #2a9bc0;
    }
  }
`

const AddCrewButton = styled(Button)`
  && {
    background: ${COLORS.THEME.SECONDARY};

    &:hover {
      background: #97125f;
    }
  }
`

export {
  PageContainer,
  CrewPanelsContainer,
  AddCrewButtonsContainer,
  CancelButton,
  SubmitButton,
  AddCrewButton,
}
