import React from 'react'
import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

import { COLORS } from '../../../constants/colors'

import 'react-toastify/dist/ReactToastify.css'

const ToastWrapper = styled.div`
  .Toastify__toast {
    border-radius: 4px;
    opacity: 1;
  }
  .Toastify__toast--success {
    background: ${COLORS.THEME.PRIMARY};
  }
  .Toastify__toast-body {
    margin: auto 10px;
  }
`

const Toaster = () => (
  <ToastWrapper>
    <ToastContainer hideProgressBar />
  </ToastWrapper>
)

export { Toaster }
