import { createReducer } from '@soltalabs/react.redux-toolkit'
import { initializeCurrentUser, currentUserChanged, signOut } from './actions'

const INITIAL_STATE = Object.freeze({
  currentUser: undefined,
})

const authReducer = createReducer(INITIAL_STATE, {
  [initializeCurrentUser.succeeded]: (state, action) => ({
    ...state,
    currentUser: action.payload.currentUser,
  }),

  [currentUserChanged]: (state, action) => ({
    ...state,
    currentUser: action.payload.currentUser,
  }),

  [signOut.succeeded]: () => INITIAL_STATE,
})

export { authReducer }
