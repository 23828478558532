import { Tabs as TabsBase, Tab as TabBase } from '@material-ui/core'
import styled from 'styled-components'
import { COLORS } from '../../../constants/colors'

const Tabs = styled(TabsBase)`
  height: 82px;

  .MuiTabs-fixed {
    align-items: center;
    display: flex;
  }

  .MuiTabs-indicator {
    height: 4px;
    background-color: ${COLORS.THEME.PRIMARY};
  }
`

const Tab = styled(TabBase)`
  && {
    height: 100px;

    font-size: 16px;
    font-weight: 800;
    line-height: 20px;
    color: ${COLORS.THEME.PRIMARY};
    min-width: 0px;
    padding: 0 15px;
  }
`

export { Tabs, Tab }
