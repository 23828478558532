const getReactEnvVariable = (key) => {
  return process.env[`REACT_APP_${key}`]
}

const FORECAST_POLYGONS_ENDPOINT = getReactEnvVariable('FORECAST_POLYGONS_ENDPOINT')
const DEPLOYMENT_ENV = getReactEnvVariable('DEPLOYMENT_ENV')
const MAP_BOX_ACCESS_TOKEN = getReactEnvVariable('MAP_BOX_ACCESS_TOKEN')
const MAP_BOX_API_NAME = getReactEnvVariable('MAP_BOX_API_NAME')
const MAP_BOX_API_ENDPOINT = getReactEnvVariable('MAP_BOX_API_ENDPOINT')
const AWS_IDENTITY_POOL_ID = getReactEnvVariable('AWS_IDENTITY_POOL_ID')
const AWS_REGION = getReactEnvVariable('AWS_REGION')
const AWS_IDENTITY_POOL_REGION = getReactEnvVariable('AWS_IDENTITY_POOL_REGION')
const USER_POOL_ID = getReactEnvVariable('USER_POOL_ID')
const USER_POOL_WEB_CLIENT_ID = getReactEnvVariable('USER_POOL_WEB_CLIENT_ID')
const PROFILE_API_NAME = getReactEnvVariable('PROFILE_API_NAME')
const PROFILE_API_ENDPOINT = getReactEnvVariable('PROFILE_API_ENDPOINT')
const RISK_ASSESSMENT_API_NAME = getReactEnvVariable('RISK_ASSESSMENT_API_NAME')
const RISK_ASSESSMENT_API_ENDPOINT = getReactEnvVariable('RISK_ASSESSMENT_API_ENDPOINT')

export {
  MAP_BOX_ACCESS_TOKEN,
  MAP_BOX_API_NAME,
  MAP_BOX_API_ENDPOINT,
  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_IDENTITY_POOL_REGION,
  USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID,
  PROFILE_API_NAME,
  PROFILE_API_ENDPOINT,
  RISK_ASSESSMENT_API_NAME,
  RISK_ASSESSMENT_API_ENDPOINT,
  DEPLOYMENT_ENV,
  FORECAST_POLYGONS_ENDPOINT,
}
