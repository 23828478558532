import Amplify, { Auth } from 'aws-amplify'

import {
  AWS_IDENTITY_POOL_ID,
  AWS_REGION,
  AWS_IDENTITY_POOL_REGION,
  USER_POOL_ID,
  USER_POOL_WEB_CLIENT_ID,
  PROFILE_API_NAME,
  PROFILE_API_ENDPOINT,
  RISK_ASSESSMENT_API_NAME,
  RISK_ASSESSMENT_API_ENDPOINT,
  MAP_BOX_API_NAME,
  MAP_BOX_API_ENDPOINT,
} from '../../utils/env'

const configureAmplify = () => {
  Amplify.configure({
    Auth: {
      identityPoolId: AWS_IDENTITY_POOL_ID,
      region: AWS_REGION,
      identityPoolRegion: AWS_IDENTITY_POOL_REGION,
      userPoolId: USER_POOL_ID,
      userPoolWebClientId: USER_POOL_WEB_CLIENT_ID,
    },
    API: {
      endpoints: [
        {
          name: PROFILE_API_NAME,
          endpoint: PROFILE_API_ENDPOINT,
          // eslint-disable-next-line camelcase
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            }
          },
        },
        {
          name: RISK_ASSESSMENT_API_NAME,
          endpoint: RISK_ASSESSMENT_API_ENDPOINT,
          // eslint-disable-next-line camelcase
          custom_header: async () => {
            return {
              Authorization: `Bearer ${(await Auth.currentSession())
                .getAccessToken()
                .getJwtToken()}`,
            }
          },
        },
        {
          name: MAP_BOX_API_NAME,
          endpoint: MAP_BOX_API_ENDPOINT,
        },
      ],
    },
  })
}

export { configureAmplify }
