import { prop, isNil } from '@soltalabs/ramda-extra'
import moment from 'moment'
import React from 'react'

import { JOBS } from '../../../constants/jobs'
import { getColorFromAssessmentScore } from '../../../utils/assessment'
import { AssessmentTooltipContents } from '../../main/AssessmentTooltipContents'

import {
  NameLabelContainer,
  JobContainer,
  JobContent,
  NameLabel,
  SectionContainer,
  InfoLabel,
  ProductDetailsContainer,
  ColorBar,
  FinishByContainer,
  FinishBy,
  DayLabel,
  AssessmentContainer,
  ProductColorBar,
  StyledLinearProgress,
  InfoContainer,
  StyledTooltip,
} from './styles'

const WrappedColorBar = React.forwardRef((props, ref) => {
  //  Spread the props to the underlying DOM element.
  return <ColorBar {...props} ref={ref} />
})

const ScheduledJobCardView = ({ job, cols, spacing, product }) => {
  const isHighRisk = job.finishBy === JOBS.MAX_FINISH_BY_DATE || isNil(job.finishBy)
  const scheduledAt = prop('scheduledAt', job)

  return (
    <JobContainer
      cols={cols}
      spacing={spacing}
      onMouseDown={(e) => e.stopPropagation()}
      onMouseUp={(e) => e.stopPropagation()}
    >
      <JobContent>
        <NameLabelContainer>
          <NameLabel>{job.jobName}</NameLabel>
        </NameLabelContainer>

        <InfoContainer>
          {scheduledAt && (
            <InfoLabel>
              Scheduled at: <b>{moment(scheduledAt).format('dddd Do MMM')}</b>
            </InfoLabel>
          )}

          <InfoLabel>
            Location: <b>{job.address || 'ADDRESS'}</b>
          </InfoLabel>
        </InfoContainer>

        <SectionContainer>
          <ProductColorBar color={prop('color', product) || 'blue'} />

          <ProductDetailsContainer>
            <InfoLabel>
              Product:{' '}
              <b style={{ color: prop('color', product) || 'blue' }}>
                {prop('name', product)}
              </b>
            </InfoLabel>

            <InfoLabel>
              Application Rate: <b>{prop('applicationRate', product)}</b>
            </InfoLabel>
          </ProductDetailsContainer>

          <FinishByContainer>
            <InfoLabel>Finish by: </InfoLabel>
            <FinishBy highRisk={isHighRisk}>
              {!isHighRisk ? moment(job.finishBy).format('DD MMM H:mm') : 'High Risk'}
            </FinishBy>
          </FinishByContainer>
        </SectionContainer>

        <SectionContainer>
          <DayLabel align="left">NOW</DayLabel>
          <DayLabel>TOMORROW</DayLabel>
          <DayLabel align="right">DAY AFTER</DayLabel>
        </SectionContainer>

        {job.assessments ? (
          <AssessmentContainer>
            {job.assessments.map((assessment, i) => (
              <StyledTooltip
                key={i}
                title={
                  <AssessmentTooltipContents
                    product={product}
                    assessment={assessment}
                  />
                }
                placement="top"
              >
                <WrappedColorBar
                  color={getColorFromAssessmentScore(assessment.score)}
                />
              </StyledTooltip>
            ))}
          </AssessmentContainer>
        ) : (
          <StyledLinearProgress />
        )}
      </JobContent>
    </JobContainer>
  )
}

export { ScheduledJobCardView }
