import { API, Auth } from 'aws-amplify'
import fetch from 'isomorphic-fetch'
import { schema, normalize } from 'normalizr'

import { RISK_ASSESSMENT_API_NAME, RISK_ASSESSMENT_API_ENDPOINT } from '../../utils/env'

const jobsSchema = new schema.Entity('jobs')

const JobService = {
  async list(sortBy, next) {
    const response = await API.get(RISK_ASSESSMENT_API_NAME, '/jobs', {
      queryStringParameters: { sortBy, next },
    })
    const normalized = normalize(response.jobs, [jobsSchema])
    const { jobs } = normalized.entities
    const ids = normalized.result
    const { sortBy: newSortBy, next: newNext } = response.paging

    return { newSortBy, newNext, jobs, ids }
  },

  async listScheduled(crewId) {
    const response = await API.get(RISK_ASSESSMENT_API_NAME, '/scheduled-jobs', {
      queryStringParameters: { crew: crewId },
    })
    const normalized = normalize(response.jobs, [jobsSchema])
    const { jobs } = normalized.entities

    return jobs
  },

  async listUnassigned(sortBy, next) {
    const response = await API.get(RISK_ASSESSMENT_API_NAME, '/unassigned-jobs', {
      queryStringParameters: { sortBy, next },
    })
    const normalized = normalize(response.jobs, [jobsSchema])
    const { jobs } = normalized.entities
    const ids = normalized.result
    const { sortBy: newSortBy, next: newNext } = response.paging

    return { newSortBy, newNext, jobs, ids }
  },

  async update(jobUpdate) {
    return API.patch(RISK_ASSESSMENT_API_NAME, `/jobs/${jobUpdate.id}`, {
      body: jobUpdate,
    })
  },

  async bulkUpdate(jobUpdates) {
    const response = await API.patch(RISK_ASSESSMENT_API_NAME, '/jobs', {
      body: jobUpdates,
    })
    const normalized = normalize(response, [jobsSchema])
    const { jobs } = normalized.entities

    return jobs
  },

  async schedule(jobId, crewId, scheduledAt) {
    return API.patch(RISK_ASSESSMENT_API_NAME, `/jobs/${jobId}/schedule`, {
      body: { crewId, scheduledAt },
    })
  },

  async upload(formData) {
    const authSession = await Auth.currentSession()
    const jwtToken = authSession.getAccessToken().getJwtToken()

    return fetch(`${RISK_ASSESSMENT_API_ENDPOINT}/jobs`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
      body: formData,
    })
  },

  async add(jobValues) {
    return API.post(RISK_ASSESSMENT_API_NAME, '/jobs', {
      body: jobValues,
    })
  },

  async delete(id) {
    const { jobId } = await API.del(RISK_ASSESSMENT_API_NAME, `/jobs/${id}`)
    return jobId
  },
}

export { JobService }
