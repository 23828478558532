import { routerMiddleware as createRouterMiddleware } from 'connected-react-router'
import { createStore, applyMiddleware, compose } from 'redux'
import { createLogger as createLoggerMiddleware } from 'redux-logger'
import createSagaMiddleware from 'redux-saga'

import { createRootReducer } from './root.reducer'
import { rootSaga } from './root.saga'

const withDevTools = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const configureStore = (history) => {
  const sagaMiddleware = createSagaMiddleware()
  const routerMiddleware = createRouterMiddleware(history)

  const middlewares = [sagaMiddleware, routerMiddleware]

  if (process.env.NODE_ENV !== 'production') {
    const loggerMiddleware = createLoggerMiddleware({ level: 'info', collapsed: true })

    middlewares.push(loggerMiddleware)
  }

  const appMiddleware = withDevTools(applyMiddleware(...middlewares))

  const rootReducer = createRootReducer(history)

  const store = createStore(rootReducer, {}, appMiddleware)

  sagaMiddleware.run(rootSaga)

  return store
}

export { configureStore }
