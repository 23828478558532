import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  deleteTempCrew,
  createTempCrew,
  createCrew,
  fetchCrews,
  getCrewsWithMembers,
  getIsFetchCrewsLoading,
} from '../../../modules/crews'

import { CrewsPageView } from './view'

function CrewsPage() {
  const dispatch = useDispatch()

  const crews = useSelector(getCrewsWithMembers)
  const isFetchCrewsLoading = useSelector(getIsFetchCrewsLoading)

  const tempCrewRef = useRef(null)

  useEffect(() => {
    dispatch(fetchCrews.request())
  }, [dispatch])

  function onAddCrewButtonClick() {
    dispatch(createTempCrew())
  }

  function onSubmitButtonClick() {
    const tempCrewName = tempCrewRef.current
    return dispatch(createCrew.request(tempCrewName))
  }

  function onCancelButtonClick() {
    dispatch(deleteTempCrew())
  }

  return (
    <CrewsPageView
      crews={crews}
      tempCrewRef={tempCrewRef}
      onAddCrewButtonClick={onAddCrewButtonClick}
      onSubmitButtonClick={onSubmitButtonClick}
      onCancelButtonClick={onCancelButtonClick}
      isFetchCrewsLoading={isFetchCrewsLoading}
    />
  )
}

export { CrewsPage }
