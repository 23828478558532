import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div.attrs({
  className: 'sk-chasing-dots',
})`
  & .sk-child {
    background-color: blue;
  }
`

const Spinner = () => (
  <Wrapper customPosition>
    <div className="sk-child sk-dot1" />
    <div className="sk-child sk-dot2" />
  </Wrapper>
)

export default Spinner
