import { values } from '@soltalabs/ramda-extra'
import {
  createSelector,
  composeSelectors,
  createIsLoadingSelector,
} from '@soltalabs/react.redux-toolkit'

import { fetchCrews } from './actions'

const getModuleState = createSelector('crews')

const getMemberEntities = composeSelectors(
  getModuleState,
  createSelector('memberEntities')
)

const getCrewEntities = composeSelectors(getModuleState, createSelector('crewEntities'))

const getCrews = composeSelectors(getCrewEntities, (entities) => values(entities))

const getCrewMemberIds = composeSelectors(
  getCrewEntities,
  (_, crewId) => crewId,
  (entities, crewId) => entities[crewId].members
)

const getCrewsWithMembers = composeSelectors(
  getCrewEntities,
  getMemberEntities,
  (crewEntities, memberEntities) => {
    const crews = values(crewEntities)

    const crewsWithMembers = crews.map((crew) => {
      const { members: memberIds } = crew
      const members = memberIds && memberIds.map((id) => memberEntities[id])

      return {
        ...crew,
        members,
      }
    })

    return crewsWithMembers
  }
)

const getIsFetchCrewsLoading = createIsLoadingSelector([fetchCrews])

export { getCrews, getCrewMemberIds, getCrewsWithMembers, getIsFetchCrewsLoading }
