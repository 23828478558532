import { defaultTo, isNaN } from '@soltalabs/ramda-extra'

import { COLORS } from '../constants/colors'

const THRESHOLDS = {
  RS1: {
    TEMP: {
      MIN: 15,
    },
    HUMIDITY: {
      MAX: 80,
    },
    RAINFALL: {
      MAX: 0.1,
    },
  },
  POLY: {
    TEMP: {
      MIN: 15,
    },
    HUMIDITY: {
      MAX: 80,
    },
    RAINFALL: {
      MAX: 0.1,
    },
  },
}

const DEFAULT_THRESHOLD = {
  TEMP: {
    MIN: 15,
  },
  HUMIDITY: {
    MAX: 80,
  },
  RAINFALL: {
    MAX: 0.1,
  },
}

function assessTemperature(algorithm, temperature) {
  if (isNaN(temperature)) {
    return 0
  }
  const tempThreshold = defaultTo(DEFAULT_THRESHOLD, THRESHOLDS[algorithm]).TEMP
  return temperature > tempThreshold.MIN ? 0 : 1
}

function assessHumidity(algorithm, humidity) {
  if (isNaN(humidity)) {
    return 0
  }
  const humidityThreshold = defaultTo(DEFAULT_THRESHOLD, THRESHOLDS[algorithm]).HUMIDITY
  return humidity < humidityThreshold.MAX ? 0 : 1
}

function assessRainfall(algorithm, rainfall) {
  if (isNaN(rainfall)) {
    return 0
  }

  const rainfallThreshold = defaultTo(DEFAULT_THRESHOLD, THRESHOLDS[algorithm]).RAINFALL
  return rainfall < rainfallThreshold.MAX ? 0 : 1
}

function getColorFromAssessmentScore(value) {
  if (value <= 0) {
    return COLORS.ASSESSMENT.GREEN
  }
  if (value < 1) {
    return COLORS.ASSESSMENT.YELLOW
  }

  return COLORS.ASSESSMENT.RED
}

export {
  assessTemperature,
  assessHumidity,
  assessRainfall,
  getColorFromAssessmentScore,
}
