import { IconButton } from '@material-ui/core'
import styled from 'styled-components'

import { COLORS } from '../../../constants/colors'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  border-top: 1px solid rgba(26, 25, 25, 0.2);
`

const MemberInfoContainer = styled.div`
  display: flex;
  align-items: center;
  width: 95%;
`

const MemberNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 15%;
  padding-left: 20px;
  height: 36px;
  justify-content: center;
`

const MemberName = styled.div`
  font-size: 16px;
`

const MemberTitle = styled.div`
  font-size: 12px;
  color: #888888;
`

const MemberEmail = styled.a`
  color: ${COLORS.THEME.PRIMARY};
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
`

const Divider = styled.div`
  width: 1px;
  height: 36px;
  background: rgba(26, 25, 25, 0.2);
  margin: 0 40px;
`

const RemoveMemberButton = styled(IconButton)`
  && {
    padding: 0;
  }
`

export {
  Container,
  MemberInfoContainer,
  MemberNameContainer,
  MemberName,
  MemberTitle,
  MemberEmail,
  Divider,
  RemoveMemberButton,
}
