import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { getIsJobDeleting, setDeletingJob } from '../../../modules/job'
import { selectProductForJob } from '../../../modules/product'
import { deleteUnassignedJob } from '../../../modules/scheduler'
import { JobCardView } from '../../main/JobCard/view'

function UnassignedJobCard(props) {
  const dispatch = useDispatch()

  const isDeleting = useSelector((state) => getIsJobDeleting(state, props))
  const product = useSelector((state) => selectProductForJob(state, props))

  function onDeletingJob(e) {
    e.stopPropagation()
    dispatch(setDeletingJob(props.job.id))
  }

  function onUndoDeleteJob(e) {
    e.stopPropagation()
    dispatch(setDeletingJob(null))
  }

  function onDeleteJob(e) {
    e.stopPropagation()
    dispatch(setDeletingJob(props.job.id))
    dispatch(deleteUnassignedJob.request(props.job.id))
  }

  return (
    <JobCardView
      {...props}
      isDeleting={isDeleting}
      product={product}
      onDeletingJob={onDeletingJob}
      onUndoDeleteJob={onUndoDeleteJob}
      onDeleteJob={onDeleteJob}
    />
  )
}

export { UnassignedJobCard }
