import { prop, path, reduce, addIndex, map, sortBy } from '@soltalabs/ramda-extra'
import {
  createSelector,
  composeSelectors,
  createIsLoadingSelector,
} from '@soltalabs/react.redux-toolkit'

import { getSelectedForecastTime } from '../forecast'

import { fetchJobs, fetchNextJobs, addJob, deleteJob } from './actions'
import { reduceIndexMap, getAssessmentScore, getPinColor } from './helpers'

const getModuleState = createSelector('jobs')

const getSortBy = composeSelectors(getModuleState, createSelector('sortBy'))

const getNextPageId = composeSelectors(getModuleState, createSelector('next'))
const getJobEntities = composeSelectors(getModuleState, createSelector('jobEntities'))
const getJobIds = composeSelectors(getModuleState, createSelector('jobIds'))
const getJobs = composeSelectors(getJobEntities, getJobIds, (entities, ids) =>
  map((id) => prop(id, entities), ids)
)
const getJobsSortedByPriorityNumber = composeSelectors(getJobs, (jobs) =>
  sortBy(prop('priorityNumber'), jobs)
)

const getPriorityNumberMap = composeSelectors(
  getJobsSortedByPriorityNumber,
  addIndex(reduce)(reduceIndexMap, {})
)

const getPriorityNumberForJob = composeSelectors(
  getPriorityNumberMap,
  (_, props) => props.job.priorityNumber,
  (indexMap, priorityNumber) => indexMap[priorityNumber]
)

const getAddJobAddress = composeSelectors(
  getModuleState,
  createSelector('addJobAddress')
)
const getAddJobLocation = composeSelectors(
  getModuleState,
  createSelector('addJobLocation')
)
const getSelectedJobId = composeSelectors(
  getModuleState,
  createSelector('selectedJobId')
)
const getHoveredJobId = composeSelectors(getModuleState, createSelector('hoveredJobId'))
const getDeletingJobId = composeSelectors(
  getModuleState,
  createSelector('deletingJobId')
)

const getIsJobSelected = composeSelectors(
  getSelectedJobId,
  (_, props) => props.job.id,
  (selectedJobId, jobId) => selectedJobId === jobId
)

const getIsJobHovered = composeSelectors(
  getHoveredJobId,
  (_, props) => props.job.id,
  (hoveredJobId, jobId) => hoveredJobId === jobId
)

const getIsJobDeleting = composeSelectors(
  getDeletingJobId,
  (_, props) => props.job.id,
  (deletingJobId, jobId) => deletingJobId === jobId
)

const getJobPinColor = composeSelectors(
  getSelectedForecastTime,
  (_, props) => path(['job', 'assessments'], props),
  (selectedTime, assessments) => {
    const score = getAssessmentScore(assessments, selectedTime)
    return getPinColor(score)
  }
)

const getIsFetchJobsLoading = createIsLoadingSelector([fetchJobs])
const getIsFetchNextJobsLoading = createIsLoadingSelector([fetchNextJobs])
const getIsAddJobLoading = createIsLoadingSelector([addJob])
const getIsDeleteJobLoading = createIsLoadingSelector([deleteJob])

export {
  getSortBy,
  getNextPageId,
  getJobEntities,
  getJobIds,
  getJobs,
  getPriorityNumberForJob,
  getAddJobAddress,
  getAddJobLocation,
  getSelectedJobId,
  getIsJobSelected,
  getHoveredJobId,
  getIsJobHovered,
  getDeletingJobId,
  getIsJobDeleting,
  getJobPinColor,
  getIsFetchJobsLoading,
  getIsFetchNextJobsLoading,
  getIsAddJobLoading,
  getIsDeleteJobLoading,
}
