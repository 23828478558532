import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { deleteCrewInvitation } from '../../../modules/crews'

import { CrewInvitationRowView } from './view'

function CrewInvitationRow({ crewId, invitation }) {
  const dispatch = useDispatch()
  const [showDeleteButton, setShowDeleteButton] = useState(false)

  function onMouseEnter() {
    setShowDeleteButton(true)
  }

  function onMouseLeave() {
    setShowDeleteButton(false)
  }

  function onDeleteInvitationButtonClick() {
    dispatch(deleteCrewInvitation.request(crewId, invitation.id))
  }

  return (
    <CrewInvitationRowView
      invitation={invitation}
      showDeleteButton={showDeleteButton}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onDeleteInvitationButtonClick={onDeleteInvitationButtonClick}
    />
  )
}

export { CrewInvitationRow }
