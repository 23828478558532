import { values, keys } from '@soltalabs/ramda-extra'
import {
  createSelector,
  composeSelectors,
  createIsLoadingSelector,
} from '@soltalabs/react.redux-toolkit'

import { fetchForecasts } from './actions'

const getModuleState = createSelector('forecasts')

const getForecastEntities = composeSelectors(getModuleState, createSelector('entities'))

const getForecasts = composeSelectors(getForecastEntities, (entities) =>
  values(entities)
)

const getForecastStartTimes = composeSelectors(getForecastEntities, (entities) =>
  keys(entities)
)

const getSelectedForecastTime = composeSelectors(
  getModuleState,
  createSelector('selectedTime')
)

const getSelectedForecast = composeSelectors(
  getForecastEntities,
  getSelectedForecastTime,
  (entities, selectedTime) => {
    if (!entities || !selectedTime) return null
    return entities[selectedTime]
  }
)

const getIsFetchForecastsLoading = createIsLoadingSelector([fetchForecasts])

export {
  getForecasts,
  getForecastStartTimes,
  getSelectedForecastTime,
  getSelectedForecast,
  getIsFetchForecastsLoading,
}
