import React from 'react'

import {
  FormContainer,
  FormSection,
  InputSection,
  FieldContainer,
  InputLabel,
  SignUpTitle,
  SignUpButton,
  StyledInput,
  FooterSection,
  SignInLinkContainer,
  SignInLink,
} from './styles'

const SignUpView = ({
  onSignUp,
  firstName,
  lastName,
  email,
  password,
  onValueChange,
  onBackToSignIn,
}) => (
  <FormContainer>
    <FormSection>
      <SignUpTitle>Create your account</SignUpTitle>
      <form onSubmit={onSignUp}>
        <InputSection>
          <FieldContainer>
            <InputLabel>First Name *</InputLabel>
            <StyledInput
              type="text"
              placeholder="Enter your first name"
              value={firstName}
              onChange={onValueChange('firstName')}
            />
          </FieldContainer>
          <FieldContainer>
            <InputLabel>Last Name *</InputLabel>
            <StyledInput
              type="text"
              placeholder="Enter your last name"
              value={lastName}
              onChange={onValueChange('lastName')}
            />
          </FieldContainer>
          <FieldContainer>
            <InputLabel>Email *</InputLabel>
            <StyledInput
              type="text"
              name="email"
              placeholder="Email"
              value={email}
              onChange={onValueChange('email')}
            />
          </FieldContainer>
          <FieldContainer>
            <InputLabel>Password *</InputLabel>
            <StyledInput
              type="password"
              name="password"
              placeholder="Password"
              value={password}
              onChange={onValueChange('password')}
            />
          </FieldContainer>
        </InputSection>

        <FooterSection>
          <SignInLinkContainer>
            <span>Have an account? </span>
            <SignInLink onClick={onBackToSignIn}>Sign in</SignInLink>
          </SignInLinkContainer>
          <SignUpButton type="submit" value="CREATE ACCOUNT" />
        </FooterSection>
      </form>
    </FormSection>
  </FormContainer>
)

export { SignUpView }
