import React from 'react'

import { Container, Title, Link, Text } from '../common/styles'

function AccountActivationPage() {
  return (
    <Container>
      <Title>Account activated!</Title>
      <Text>
        <b>Welcome to Zeus!</b> Return to the homepage and login to get started.
      </Text>

      <Link to="/">RETURN TO HOMEPAGE</Link>
    </Container>
  )
}

export { AccountActivationPage }
