import EmailIcon from '@material-ui/icons/Email'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import React from 'react'

import { COLORS } from '../../../constants/colors'
import { CREWS } from '../../../constants/crews'
import { deepMemo } from '../../../utils/react'

import {
  Container,
  InvitationInfoContainer,
  InvitationStatus,
  InvitationEmail,
  DeleteInvitationButton,
  Divider,
} from './styles'

function View({
  invitation,
  showDeleteButton,
  onMouseEnter,
  onMouseLeave,
  onDeleteInvitationButtonClick,
}) {
  return (
    <Container onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <InvitationInfoContainer>
        <EmailIcon fontSize="large" style={{ color: COLORS.THEME.SECONDARY }} />
        <InvitationStatus status={invitation.status}>
          {CREWS.INVITATION_STATUS_NAME_MAP[invitation.status]}
        </InvitationStatus>
        <Divider />
        <InvitationEmail href={`mailto:${invitation.email}`}>
          {invitation.email}
        </InvitationEmail>
      </InvitationInfoContainer>
      {showDeleteButton && (
        <DeleteInvitationButton size="small" onClick={onDeleteInvitationButtonClick}>
          <RemoveCircleIcon color="secondary" />
        </DeleteInvitationButton>
      )}
    </Container>
  )
}

const CrewInvitationRowView = deepMemo(View)

export { CrewInvitationRowView }
