import React from 'react'

import { deepMemo } from '../../../utils/react'
import { Container, TitleLabel } from '../../common/styles'
import { SortbySelect } from '../../main/SortbySelect'
import { UnassignedJobList } from '../UnassignedJobList'

function View({ sortBy, onSelectSortBy }) {
  return (
    <Container>
      <TitleLabel>Unassigned Jobs</TitleLabel>
      <SortbySelect value={sortBy} onChange={onSelectSortBy} />

      <UnassignedJobList />
    </Container>
  )
}

const UnassignedJobsSectionView = deepMemo(View)

export { UnassignedJobsSectionView }
