import React from 'react'
import { TourMethods } from 'react-shepherd'
import { Modal } from '@material-ui/core'

import { defineRavensdownRules } from '../../abilities/common'
import { Can } from '@casl/react'

import { NavigationTabs } from './NavigationTabs'
import { SupportForm } from '../support/SupportForm'

import { useSupportModule } from '../../modules/support'

import { ReactComponent as RoadScienceLogo } from '../../assets/road-science.svg'
import { ReactComponent as ZeusLogo } from '../../assets/zeus.svg'
import { ReactComponent as NotificationIcon } from '../../assets/notification-icon.svg'
import { ReactComponent as QuestionIcon } from '../../assets/question-icon.svg'
import { ReactComponent as HelpIcon } from '../../assets/help.svg'

import {
  HeaderContainer,
  HeaderContent,
  StyledDivider,
  SectionContainer,
  WelcomeLabel,
  StyledFab,
  StyledButton,
  TabsContainer,
} from './styles'

const HeaderView = ({ isLoggedIn, userInfo, onSignOut }) => {
  const ravensdownAbilities = defineRavensdownRules(userInfo)
  const {
    showModal,
    dispatchHideSupportModal,
    dispatchShowSupportModal,
  } = useSupportModule()

  return (
    <HeaderContainer>
      <Modal open={showModal} onClose={dispatchHideSupportModal}>
        <SupportForm />
      </Modal>

      <HeaderContent>
        <SectionContainer>
          <RoadScienceLogo />
          <StyledDivider orientation="vertical" variant="middle" />
          <ZeusLogo />
        </SectionContainer>
        {isLoggedIn && (
          <TourMethods>
            {({ start: startTutorial }) => (
              <TabsContainer>
                <SectionContainer
                  style={{ flex: 1, alignItems: 'flex-start', marginLeft: '36px' }}
                >
                  <NavigationTabs userInfo={userInfo} />
                </SectionContainer>

                <SectionContainer>
                  <WelcomeLabel>
                    Welcome{userInfo && <b>, {userInfo.firstName}</b>}
                  </WelcomeLabel>

                  <Can not I="manage" on="Ravensdown" ability={ravensdownAbilities}>
                    <StyledFab>
                      <NotificationIcon fill="white" />
                    </StyledFab>

                    <StyledFab onClick={startTutorial}>
                      <QuestionIcon fill="white" />
                    </StyledFab>

                    <StyledFab onClick={dispatchShowSupportModal}>
                      <HelpIcon fill="white" height={20} width={20} />
                    </StyledFab>
                  </Can>

                  <StyledButton variant="outlined" onClick={onSignOut}>
                    SIGN OUT
                  </StyledButton>
                </SectionContainer>
              </TabsContainer>
            )}
          </TourMethods>
        )}
      </HeaderContent>
    </HeaderContainer>
  )
}

export { HeaderView }
