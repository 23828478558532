import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { fetchCrews } from '../../../modules/crews'
import {
  fetchUnassignedJobs,
  scheduleJob,
  unscheduleJob,
  reorderUnassignedJobs,
} from '../../../modules/scheduler'

import { SchedulerPageView } from './view'

function SchedulerPage() {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchUnassignedJobs.request())
    dispatch(fetchCrews.request())
  }, [dispatch])

  const onDragEnd = useCallback(
    (result) => {
      const { draggableId, source, destination } = result
      if (!draggableId || !destination) {
        return
      }
      if (
        source.droppableId === destination.droppableId &&
        source.index === destination.index
      ) {
        return
      }

      if (destination.droppableId === 'unassigned-job-list') {
        if (source.droppableId === destination.droppableId) {
          dispatch(reorderUnassignedJobs(draggableId, source.index, destination.index))
          return
        }

        dispatch(unscheduleJob.request(draggableId, destination.index))
        return
      }

      const crewId = destination.droppableId.split('/')[0]
      const scheduledAt = destination.droppableId.split('/')[1]

      dispatch(scheduleJob.request(draggableId, crewId, scheduledAt))
    },
    [dispatch]
  )

  return <SchedulerPageView onDragEnd={onDragEnd} />
}

export { SchedulerPage }
