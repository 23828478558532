import React from 'react'
import { useSelector } from 'react-redux'

import { selectProductForJob } from '../../../modules/product'

import { ScheduledJobCardView } from './view'

function ScheduledJobCard(props) {
  const product = useSelector((state) => selectProductForJob(state, props))

  return <ScheduledJobCardView {...props} product={product} />
}

export { ScheduledJobCard }
