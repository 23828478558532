import moment from 'moment'
import React from 'react'
import { Droppable, Draggable } from 'react-beautiful-dnd'

import { deepMemo } from '../../../utils/react'
import { ScheduledJobCard } from '../ScheduledJobCard'

import { Container, ListContainer, Day } from './styles'

function View({ day, crewId, jobList, onMouseDown, onMouseUp, onMouseMove }) {
  return (
    <Container
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
    >
      <Day>{day.name}</Day>
      <Droppable droppableId={`${crewId}/${moment(day.value).toISOString()}`}>
        {(droppableProvided, snapshot) => (
          <ListContainer
            {...droppableProvided.droppableProps}
            ref={droppableProvided.innerRef}
            isDraggingOver={snapshot.isDraggingOver}
          >
            {jobList.map((job, index) => (
              <Draggable key={job.id} draggableId={job.id} index={index}>
                {(draggableProvided) => (
                  <div
                    ref={draggableProvided.innerRef}
                    {...draggableProvided.draggableProps}
                    {...draggableProvided.dragHandleProps}
                  >
                    <ScheduledJobCard job={job} cols={1} spacing={10} />
                  </div>
                )}
              </Draggable>
            ))}
            {droppableProvided.placeholder}
          </ListContainer>
        )}
      </Droppable>
    </Container>
  )
}

const ScheduledJobListView = deepMemo(View)

export { ScheduledJobListView }
