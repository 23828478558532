import styled from 'styled-components'

const Container = styled.div`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  height: 77vh;
  margin: 20px 0;
  scrollbar-width: none;
`

const EmptyListPrompt = styled.div`
  margin: 20px auto;
  font-size: 24px;
  color: #a1a1a1;
`

export { Container, EmptyListPrompt }
