import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { signUpApiRequest } from '../../../api/auth'

import { AcceptInvitationPageView } from './view'

function AcceptInvitationPage(props) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [password, setPassword] = useState('')

  const invitationId = props.location.search.replace('?invitationId=', '')

  function onSignUp(e) {
    e.preventDefault()

    signUpApiRequest({ values: { firstName, lastName, password, invitationId } })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          throw Error(response.message)
        }
        toast.success('Invitation Accepted')
        props.history.push('/')
      })
      .catch((error) => {
        toast.error(`Accept Invitation Error: ${error.message}`)
      })
  }

  function onFirstNameValueChange(e) {
    setFirstName(e.target.value)
  }

  function onLastNameValueChange(e) {
    setLastName(e.target.value)
  }

  function onPasswordValueChange(e) {
    setPassword(e.target.value)
  }

  return (
    <AcceptInvitationPageView
      firstName={firstName}
      lastName={lastName}
      password={password}
      onSignUp={onSignUp}
      onFirstNameValueChange={onFirstNameValueChange}
      onLastNameValueChange={onLastNameValueChange}
      onPasswordValueChange={onPasswordValueChange}
    />
  )
}

export { AcceptInvitationPage }
