import { createActionNamespace } from '@soltalabs/react.redux-toolkit'

const { createActionCreator, createAsyncActionCreators } = createActionNamespace(
  'forecasts'
)

const fetchForecasts = createAsyncActionCreators('FETCH_FORECASTS', [
  (productId) => ({ productId }),
  (forecasts) => ({ forecasts }),
  (errorMessage) => ({ errorMessage }),
])

const updateSelectedForecastTime = createActionCreator(
  'UPDATE_SELECTED_FORECAST_TIME',
  (selectedTime) => ({
    selectedTime,
  })
)

export { fetchForecasts, updateSelectedForecastTime }
