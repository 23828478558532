import { createActionNamespace } from '@soltalabs/react.redux-toolkit'

const { createActionCreator, createAsyncActionCreators } = createActionNamespace(
  'scheduler'
)

const fetchScheduledJobs = createAsyncActionCreators('FETCH_SCHEDULED_JOBS', [
  (crewId) => ({ crewId }),
  (jobs = {}) => ({ jobs }),
  (errorMessage) => ({ errorMessage }),
])

const fetchUnassignedJobs = createAsyncActionCreators('FETCH_UNASSIGNED_JOBS', [
  (sortBy, next) => ({ sortBy, next }),
  (sortBy, next, jobs = {}, ids = []) => ({ sortBy, next, jobs, ids }),
  (errorMessage) => ({ errorMessage }),
])

const fetchNextUnassignedJobs = createAsyncActionCreators(
  'FETCH_NEXT_UNASSIGNED_JOBS',
  [
    (sortBy, next) => ({ sortBy, next }),
    (sortBy, next, jobs = {}, ids = []) => ({ sortBy, next, jobs, ids }),
    (errorMessage) => ({ errorMessage }),
  ]
)

const updateSingleUnassignedJob = createAsyncActionCreators(
  'UPDATE_SINGLE_UNASSIGNED_JOB',
  [
    (jobUpdate) => ({ jobUpdate }),
    (job = {}) => ({ job }),
    (errorMessage) => ({ errorMessage }),
  ]
)

const updateMultipleUnassignedJobs = createAsyncActionCreators(
  'UPDATE_MULTIPLE_UNASSIGNED_JOBS',
  [
    (jobUpdates) => ({ jobUpdates }),
    (jobs = {}) => ({ jobs }),
    (errorMessage) => ({ errorMessage }),
  ]
)

const scheduleJob = createAsyncActionCreators('SCHEDULE_JOB', [
  (jobId, crewId, scheduledAt) => ({ jobId, crewId, scheduledAt }),
  (job = {}) => ({ job }),
  (errorMessage) => ({ errorMessage }),
])

const unscheduleJob = createAsyncActionCreators('UNSCHEDULE_JOB', [
  (id, index) => ({ id, index }),
  (job = {}) => ({ job }),
  (errorMessage) => ({ errorMessage }),
])

const reorderUnassignedJobs = createActionCreator(
  'REORDER_UNASSIGNED_JOBS',
  (id, sourceIndex, destinationIndex) => ({
    id,
    sourceIndex,
    destinationIndex,
  })
)

const deleteUnassignedJob = createAsyncActionCreators('DELETE_UNASSIGNED_JOB', [
  (id) => ({ id }),
  (id) => ({ id }),
  (errorMessage) => ({ errorMessage }),
])

export {
  fetchScheduledJobs,
  fetchUnassignedJobs,
  fetchNextUnassignedJobs,
  updateSingleUnassignedJob,
  updateMultipleUnassignedJobs,
  scheduleJob,
  unscheduleJob,
  reorderUnassignedJobs,
  deleteUnassignedJob,
}
