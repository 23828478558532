import React from 'react'
import { useSelector } from 'react-redux'

import { getScheduledJobsForCrewAndDay } from '../../../modules/scheduler'

import { ScheduledJobListView } from './view'

function ScheduledJobList(props) {
  const scheduledJobs = useSelector((state) =>
    getScheduledJobsForCrewAndDay(state, props)
  )

  return <ScheduledJobListView {...props} jobList={scheduledJobs} />
}

export { ScheduledJobList }
