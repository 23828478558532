import { API } from 'aws-amplify'
import { schema, normalize } from 'normalizr'

import { CREWS } from '../../constants/crews'
import { PROFILE_API_NAME } from '../../utils/env'

const memberSchema = new schema.Entity('members')
const crewSchema = new schema.Entity('crews', {
  members: [memberSchema],
})

const CrewService = {
  async list() {
    const response = await API.get(PROFILE_API_NAME, '/crews')
    const normalized = normalize(response.crews, [crewSchema])
    const { crews, members } = normalized.entities

    return { crews, members }
  },

  async create(name) {
    const response = await API.post(PROFILE_API_NAME, '/crews', {
      body: { name },
    })

    const normalized = normalize(response, crewSchema)
    const { crews: crew } = normalized.entities

    return crew
  },

  async delete(id) {
    return API.del(PROFILE_API_NAME, `/crews/${id}`)
  },

  async watch(id) {
    return API.post(PROFILE_API_NAME, `/crews/${id}/tracking`)
  },

  async unwatch(id) {
    return API.del(PROFILE_API_NAME, `/crews/${id}/tracking`)
  },

  async addCrewMember(crewId, memberId) {
    const response = await API.post(PROFILE_API_NAME, `/crews/${crewId}/members`, {
      body: { memberProfileId: memberId },
    })
    const normalized = normalize(response, crewSchema)
    const { crews: crew, members: member } = normalized.entities

    return { crew, member }
  },

  async removeCrewMember(crewId, memberId) {
    const response = await API.del(
      PROFILE_API_NAME,
      `/crews/${crewId}/members/${memberId}`
    )
    const normalized = normalize(response, crewSchema)
    const { crews: crew } = normalized.entities

    return crew
  },

  async createCrewInvitation(crewId, email) {
    const response = await API.post(PROFILE_API_NAME, '/invitations', {
      body: { crewId, email },
    })
    // TODO: Backend needs to return full invitation object { id, email, status }
    const invitation = {
      ...response,
      email,
      status: CREWS.INVITATION_STATUS.PENDING,
    }

    return invitation
  },

  async deleteCrewInvitation(invitationId) {
    return API.del(PROFILE_API_NAME, `/invitations/${invitationId}`)
  },
}

export { CrewService }
