import React from 'react'

import { Container, Title, Link, Text } from './styles'

function AcceptInvitationErrorPage() {
  return (
    <Container>
      <Title>Invitation Error</Title>
      <Text>
        The invitation link is invalid or has expired. Please contact Zeus support for
        help.
      </Text>

      <Link to="/">RETURN TO HOMEPAGE</Link>
    </Container>
  )
}

export { AcceptInvitationErrorPage }
