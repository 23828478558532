import { AbilityBuilder, Ability } from '@casl/ability'
import { path } from '@soltalabs/ramda-extra'

function defineRavensdownRules(user) {
  const { can, build } = new AbilityBuilder(Ability)

  const canManageRavensdown = path(['features', 'ravensdown'])(user) || false

  if (canManageRavensdown) {
    can('manage', 'Ravensdown')
  }

  return build()
}

export { defineRavensdownRules }
