import { Container, Grid } from '@material-ui/core'
import React from 'react'
import { DragDropContext } from 'react-beautiful-dnd'

import { deepMemo } from '../../../utils/react'
import { SchedulerPanelsSection } from '../SchedulerPanelsSection'
import { UnassignedJobsSection } from '../UnassignedJobsSection'

function View({ onDragEnd }) {
  return (
    <Container maxWidth="lg">
      <DragDropContext onDragEnd={onDragEnd}>
        <Grid container direction="row" spacing={4}>
          <Grid item xs={4}>
            <UnassignedJobsSection />
          </Grid>
          <Grid item xs={8}>
            <SchedulerPanelsSection />
          </Grid>
        </Grid>
      </DragDropContext>
    </Container>
  )
}

const SchedulerPageView = deepMemo(View)

export { SchedulerPageView }
