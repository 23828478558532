import { createActionNamespace } from '@soltalabs/react.redux-toolkit'

const { createActionCreator, createAsyncActionCreators } = createActionNamespace(
  'crews'
)

const fetchCrews = createAsyncActionCreators('FETCH_CREWS', [
  undefined,
  (crews = {}, members = {}) => ({ crews, members }),
  (errorMessage) => ({ errorMessage }),
])

const createTempCrew = createActionCreator('CREATE_TEMP_CREW')
const deleteTempCrew = createActionCreator('DELETE_TEMP_CREW')

const createCrew = createAsyncActionCreators('CREATE_CREW', [
  (name) => ({ name }),
  (crew = {}) => ({ crew }),
  (errorMessage) => ({ errorMessage }),
])

const deleteCrew = createAsyncActionCreators(
  'DELETE_CREW',
  [(id) => ({ id }), (id) => ({ id }), (id, errorMessage) => ({ id, errorMessage })],
  { idKey: 'id' }
)

const watchCrew = createAsyncActionCreators('WATCH_CREW', [
  (id) => ({ id }),
  (id) => ({ id }),
  (id, errorMessage) => ({ id, errorMessage }),
])

const unwatchCrew = createAsyncActionCreators('UNWATCH_CREW', [
  (id) => ({ id }),
  (id) => ({ id }),
  (id, errorMessage) => ({ id, errorMessage }),
])

const addCrewMember = createAsyncActionCreators('ADD_CREW_MEMBER', [
  (crewId, memberId) => ({ crewId, memberId }),
  (crew = {}, member = {}) => ({ crew, member }),
  (errorMessage) => ({ errorMessage }),
])

const removeCrewMember = createAsyncActionCreators('REMOVE_CREW_MEMBER', [
  (crewId, memberId) => ({ crewId, memberId }),
  (crew = {}, memberId) => ({ crew, memberId }),
  (errorMessage) => ({ errorMessage }),
])

const createCrewInvitation = createAsyncActionCreators('CREATE_CREW_INVITATION', [
  (crewId, email) => ({ crewId, email }),
  (crewId, invitation = {}) => ({ crewId, invitation }),
  (errorMessage) => ({ errorMessage }),
])

const deleteCrewInvitation = createAsyncActionCreators('DELETE_CREW_INVITATION', [
  (crewId, invitationId) => ({ crewId, invitationId }),
  (crewId, invitationId) => ({ crewId, invitationId }),
  (errorMessage) => ({ errorMessage }),
])

export {
  fetchCrews,
  createTempCrew,
  deleteTempCrew,
  createCrew,
  deleteCrew,
  watchCrew,
  unwatchCrew,
  addCrewMember,
  removeCrewMember,
  createCrewInvitation,
  deleteCrewInvitation,
}
