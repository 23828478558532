import { omit, without, mergeDeepRight, concat } from '@soltalabs/ramda-extra'
import { createReducer } from '@soltalabs/react.redux-toolkit'

import { JOBS } from '../../constants/jobs'

import {
  fetchJobs,
  fetchNextJobs,
  updateSingleJob,
  updateMultipleJobs,
  reorderJobs,
  addJob,
  setAddJobAddress,
  setAddJobLocation,
  setSelectedJob,
  setHoveredJob,
  setDeletingJob,
  deleteJob,
} from './actions'
import { mergeEntities, swapByValue } from './helpers'

const INITIAL_STATE = Object.freeze({
  sortBy: JOBS.SORTBY.PRIORITY.value,
  jobEntities: {},
  jobIds: [],
})

const jobsReducer = createReducer(INITIAL_STATE, {
  [fetchJobs.succeeded]: (state, action) => ({
    ...state,
    jobEntities: mergeEntities(state.jobEntities, action.payload.jobs),
    next: action.payload.next,
    sortBy: action.payload.sortBy,
    jobIds: action.payload.ids,
  }),
  [fetchNextJobs.succeeded]: (state, action) => ({
    ...state,
    jobEntities: mergeDeepRight(state.jobEntities, action.payload.jobs),
    next: action.payload.next,
    sortBy: action.payload.sortBy,
    jobIds: concat(state.jobIds, action.payload.ids),
  }),
  [updateSingleJob.succeeded]: (state, action) => ({
    ...state,
    jobEntities: mergeDeepRight(state.jobEntities, {
      [action.payload.job.id]: action.payload.job,
    }),
  }),
  [updateMultipleJobs.succeeded]: (state, action) => ({
    ...state,
    jobEntities: mergeDeepRight(state.jobEntities, action.payload.jobs),
  }),
  [reorderJobs]: (state, action) => ({
    ...state,
    jobEntities: mergeDeepRight(state.jobEntities, {
      [action.payload.source.id]: action.payload.source,
      [action.payload.destination.id]: action.payload.destination,
    }),
    jobIds: swapByValue(
      action.payload.source.id,
      action.payload.destination.id,
      state.jobIds
    ),
  }),
  [addJob.succeeded]: (state, action) => ({
    ...state,
    jobEntities: mergeDeepRight(state.jobEntities, {
      [action.payload.job.id]: action.payload.job,
    }),
  }),
  [setAddJobLocation]: (state, action) => ({
    ...state,
    addJobLocation: action.payload.location,
  }),
  [setAddJobAddress]: (state, action) => ({
    ...state,
    addJobAddress: action.payload.address,
  }),
  [setSelectedJob]: (state, action) => ({
    ...state,
    selectedJobId: action.payload.id,
  }),
  [setHoveredJob]: (state, action) => ({
    ...state,
    hoveredJobId: action.payload.id,
  }),
  [setDeletingJob]: (state, action) => ({
    ...state,
    deletingJobId: action.payload.id,
  }),
  [deleteJob.succeeded]: (state, action) => ({
    ...state,
    jobEntities: omit([action.payload.id], state.jobEntities),
    jobIds: without([action.payload.id], state.jobIds),
  }),
})

export { jobsReducer }
