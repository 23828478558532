const JOBS = {
  SORTBY: {
    PRIORITY: {
      value: 'priorityNumber',
      name: 'Priority',
    },
    FINISH_BY: {
      value: 'finishBy',
      name: 'Finish By',
    },
    ASSESSMENTS: {
      value: 'currentHourAssessmentScore',
      name: 'Assessments',
    },
  },
  MAX_FINISH_BY_DATE: '+275760-09-13T00:00:00.000Z',
}

export { JOBS }
