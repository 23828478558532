import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { isEmpty, isNil } from '@soltalabs/ramda-extra'
import React from 'react'

import { CREWS } from '../../../constants/crews'
import { deepMemo } from '../../../utils/react'
import { AddMemberRow } from '../AddMemberRow'
import { CrewInvitationRow } from '../CrewInvitationRow'
import { CrewMemberRow } from '../CrewMemberRow'

import {
  EmptyListPrompt,
  CrewExpansionPanel,
  CrewExpansionSummary,
  CrewNameInput,
  CrewName,
  CrewExpansionDetails,
  ButtonsContainer,
  ActionButton,
} from './styles'

function View({
  crew,
  showDeleteButton,
  isDeleting,
  onCrewNameInputChange,
  onCrewNameInputKeyPress,
  onMouseEnterPanel,
  onMouseLeavePanel,
  onWatchButtonClick,
  onUnwatchButtonClick,
  onDeleteButtonClick,
  onConfirmButtonClick,
  onUndoButtonClick,
}) {
  return (
    <CrewExpansionPanel
      defaultExpanded={true}
      onMouseEnter={onMouseEnterPanel}
      onMouseLeave={onMouseLeavePanel}
    >
      <CrewExpansionSummary expandIcon={<ExpandMoreIcon />}>
        {crew.id === CREWS.TEMP_CREW_ID && (
          <CrewNameInput
            type="text"
            placeholder="Enter a name"
            autoFocus
            onChange={onCrewNameInputChange}
            onFocus={(e) => e.stopPropagation()}
            onKeyPress={onCrewNameInputKeyPress}
          />
        )}
        <CrewName>{crew.name}</CrewName>
        {crew.id !== CREWS.TEMP_CREW_ID && (
          <ButtonsContainer>
            {showDeleteButton &&
              (isDeleting ? (
                <>
                  <ActionButton
                    color="secondary"
                    variant="contained"
                    onClick={onConfirmButtonClick}
                  >
                    Confirm
                  </ActionButton>

                  <ActionButton color="primary" onClick={onUndoButtonClick}>
                    Undo
                  </ActionButton>
                </>
              ) : (
                <ActionButton
                  color="secondary"
                  startIcon={<DeleteIcon />}
                  onClick={onDeleteButtonClick}
                >
                  Delete
                </ActionButton>
              ))}
            {crew.tracked ? (
              <ActionButton
                color="default"
                startIcon={<VisibilityIcon />}
                onClick={onUnwatchButtonClick}
              >
                Watching
              </ActionButton>
            ) : (
              <ActionButton
                color="default"
                startIcon={<VisibilityOffIcon />}
                onClick={onWatchButtonClick}
              >
                Unwatching
              </ActionButton>
            )}
          </ButtonsContainer>
        )}
      </CrewExpansionSummary>
      {!isNil(crew.members) && !isNil(crew.invitations) && (
        <CrewExpansionDetails>
          {isEmpty(crew.members) && isEmpty(crew.invitations) ? (
            <EmptyListPrompt>No members and invitations</EmptyListPrompt>
          ) : (
            <>
              {crew.members.map((member) => (
                <CrewMemberRow key={member.id} crewId={crew.id} member={member} />
              ))}
              {crew.invitations.map((invitation) => (
                <CrewInvitationRow
                  key={invitation.id}
                  crewId={crew.id}
                  invitation={invitation}
                />
              ))}
            </>
          )}
          <AddMemberRow crewId={crew.id} />
        </CrewExpansionDetails>
      )}
    </CrewExpansionPanel>
  )
}

const CrewPanelView = deepMemo(View)

export { CrewPanelView }
