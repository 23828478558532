import { isNil } from '@soltalabs/ramda-extra'
import { createSelector, composeSelectors } from '@soltalabs/react.redux-toolkit'

const getModuleState = createSelector('auth')

const getCurrentUser = composeSelectors(getModuleState, createSelector('currentUser'))
const getIsLoggedIn = composeSelectors(
  getCurrentUser,
  (currentUser) => !isNil(currentUser)
)

export { getIsLoggedIn }
