import { createActions } from 'redux-actions'

const createAsyncActionTypes = (name) => {
  const prefix = `${name.toUpperCase()}`
  return {
    PREFIX: `${prefix}`,
    REQUESTED: `${prefix}/REQUESTED`,
    SUCCEEDED: `${prefix}/SUCCEEDED`,
    FAILED: `${prefix}/FAILED`,
  }
}

const createAsyncActionCreators = (type, payloadCreators) => {
  const [
    requestedPayloadCreator,
    succeededPayloadCreator,
    failedPayloadCreator,
  ] = payloadCreators
  return createActions({
    [type.PREFIX]: {
      REQUESTED: requestedPayloadCreator,
      SUCCEEDED: succeededPayloadCreator,
      FAILED: failedPayloadCreator,
    },
  })
}

export { createAsyncActionTypes, createAsyncActionCreators }
