import { connect } from 'react-redux'
import { NavigationTabs } from './view'
import { getIsManager } from '../../../modules/organization'

const mapStateToProps = (state) => ({
  isManager: getIsManager(state),
})

const ConnectedNavigationTabs = connect(mapStateToProps)(NavigationTabs)

export { ConnectedNavigationTabs as NavigationTabs }
