import styled from 'styled-components'

import { COLORS } from '../../../constants/colors'

const FormContainer = styled.div`
  text-align: center;
  margin: 35px auto 35px;
  color: rgb(21, 41, 57);
`

const FormSection = styled.div`
  position: relative;
  margin-bottom: 20px;
  padding: 35px 40px;
  text-align: left;
  display: inline-block;
  min-width: 460px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
`

const SignUpTitle = styled.span`
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
  display: block;
`

const InputSection = styled.div`
  margin-bottom: 30px;
`

const FieldContainer = styled.div`
  display: block;
  margin-bottom: 22px;
`

const InputLabel = styled.div`
  font-size: 16px;
  margin-bottom: 8px;
`

const SignUpButton = styled.input`
  background: ${COLORS.THEME.SECONDARY};
  color: white;
  width: 170px;
  border-radius: 4px;
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.42857143;
  padding: 14px 10px;
  letter-spacing: 1.1px;
  border: none;
  outline: none;

  &:focus {
    outline: -webkit-focus-ring-color auto 5px;
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(201, 23, 126, 0.6);
    box-shadow: none;
  }
`

const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 16px;
  font-size: 16px;
  border: 1px solid var(--lightGrey);
  border-radius: 3px;
  box-sizing: border-box;
  margin-bottom: 10px;
`

const FooterSection = styled.div`
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: rgb(130, 130, 130);
`

export {
  FormContainer,
  FormSection,
  InputSection,
  FieldContainer,
  InputLabel,
  FooterSection,
  SignUpTitle,
  SignUpButton,
  StyledInput,
}
