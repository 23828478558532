const CREWS = {
  TEMP_CREW_ID: 'TEMP_CREW_ID',
  SUGGESTION_TYPES: {
    MEMBER: 'MEMBER',
    INVITE: 'INVITE',
  },
  INVITATION_STATUS: {
    PENDING: 'inv_status_pending',
  },
  INVITATION_STATUS_NAME_MAP: {
    inv_status_pending: 'PENDING INVITATION',
  },
}

export { CREWS }
